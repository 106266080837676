import { Add, Remove } from "@mui/icons-material"
import { ListItemIcon, MenuItem, MenuList, Paper, Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useMap } from "react-leaflet"
import { disablePropagation } from "./Propagation"

export const MUIZoom = () => {
    const { t } = useTranslation()
    const map = useMap()

    return (
        <div className="leaflet-top leaflet-left" ref={disablePropagation}>
            <div className="leaflet-bar leaflet-control">
                <Paper>
                    <MenuList dense>
                        <MenuItem onClick={(_) => map.zoomIn()} style={{ padding: "2px 12px 2px 12px" }}>
                            <ListItemIcon style={{ minWidth: "1px" }}>
                                <Tooltip title={t("action.zoom_in_tooltip")} disableInteractive>
                                    <Add />
                                </Tooltip>
                            </ListItemIcon>
                        </MenuItem>
                        <MenuItem onClick={(_) => map.zoomOut()} style={{ padding: "2px 12px 2px 12px" }}>
                            <ListItemIcon style={{ minWidth: "1px" }}>
                                <Tooltip title={t("action.zoom_out_tooltip")} disableInteractive>
                                    <Remove />
                                </Tooltip>
                            </ListItemIcon>
                        </MenuItem>
                    </MenuList>
                </Paper>
            </div>
        </div>
    )
}
