import { Actor, ActorType } from "../../api/Authz"
import { CustomerActors } from "../../api/portal/hooks/Actors"
import { GroupChip } from "./GroupChip"
import { UserChip } from "./UserChip"

export interface ActorChipProps {
    actor: Actor
    customerActors: CustomerActors
}

export const ActorChip = (props: ActorChipProps) => {
    const { actor, customerActors } = props

    return actor.type === ActorType.GROUP ? (
        <GroupChip group={actor} customerActors={customerActors} />
    ) : (
        <UserChip user={actor} customerActors={customerActors} />
    )
}
