import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import { Day, Hour } from "../../config/time"

export interface Range {
    label: string
    millis: number
}

export const OneWeekRange = {
    label: "1w",
    millis: 7 * Day,
}
export const ThreeDayRange = {
    label: "3d",
    millis: 3 * Day,
}
export const OneDayRange = {
    label: "1d",
    millis: Day,
}
export const SixHoursRange = {
    label: "6h",
    millis: 6 * Hour,
}
export const OneHourRange = {
    label: "1h",
    millis: Hour,
}

const ranges: Range[] = [OneWeekRange, ThreeDayRange, OneDayRange, SixHoursRange, OneHourRange]

export interface RangeChoiceProps {
    value: Range
    onChange: (range: Range) => void
}

export const RangeChoice = (props: RangeChoiceProps) => {
    const { value, onChange } = props
    return (
        <ToggleButtonGroup value={value} exclusive={true} onChange={(_, v) => v && onChange(v)}>
            {ranges.map((r) => (
                <ToggleButton key={r.label} sx={{ padding: "0.5em" }} size="small" value={r}>
                    <Typography variant="caption">{r.label}</Typography>
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    )
}
