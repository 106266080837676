import { Button, useTheme } from "@mui/material"
import { ReactElement, cloneElement } from "react"

export interface PagingButtonProps {
    disabled?: boolean
    toggled?: boolean
    icon: ReactElement
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning"
    toggledColor?: string
    onClick: () => void
}

export function PagingButton(props: PagingButtonProps) {
    const { disabled, toggled, icon, color, toggledColor, onClick } = props

    const theme = useTheme()
    const borderColor = toggled ? toggledColor || theme.palette.primary.light : undefined
    const iconColor = disabled
        ? undefined
        : toggled
          ? toggledColor || theme.palette.primary.light
          : color || theme.palette.primary.main

    return (
        <Button
            variant="outlined"
            sx={{ padding: "1px", margin: "1px", minWidth: "0px", borderColor: borderColor }}
            disabled={disabled}
            onClick={onClick}
            color={color}
        >
            {cloneElement(icon, {
                fontSize: "small",
                htmlColor: iconColor,
            })}
        </Button>
    )
}
