import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    LinearProgress,
    Stack,
    Typography,
    useTheme,
} from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useFullscreen } from "rooks"
import { ETDialogTitle } from "../components/ETDialogTitle"
import { hookInterval, hookTimeout, Minute, Second } from "../config/time"
import useVersion from "./VersionProvider"

const autoReloadSeconds = 30
const postponeMinutes = 5

export const UpgradeDialog = () => {
    const [remaining, setRemaining] = useState(autoReloadSeconds)
    const [postponed, setPostponed] = useState(false)
    const { upgraded, clientVersion, serverVersion } = useVersion()
    const { t } = useTranslation()
    const theme = useTheme()
    const { fullscreenElement } = useFullscreen()

    useEffect(() => hookTimeout(() => setPostponed(false), postponeMinutes * Minute), [postponed])

    const active = useMemo(() => upgraded && !postponed, [upgraded, postponed])

    useEffect(() => {
        if (!active) {
            setRemaining(autoReloadSeconds)
            return
        }
        return hookInterval(() => {
            setRemaining((x) => {
                if (x > 0) {
                    return x - 1
                }
                window.location.reload()
                return 0
            })
        }, Second)
    }, [active])

    if (!active) {
        return null
    }

    return (
        <Dialog maxWidth="xs" fullWidth open={true} container={fullscreenElement}>
            <ETDialogTitle />
            <DialogContent>
                <Stack spacing={1}>
                    <Typography>{t("message.upgrade_available")}</Typography>
                    <Typography color={theme.palette.text.secondary} variant="body2">
                        {t("field.version_current")}: {clientVersion}
                    </Typography>
                    <Typography color={theme.palette.text.secondary} variant="body2">
                        {t("field.version_new")}: {serverVersion}
                    </Typography>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Box flex={1}>
                            <LinearProgress variant="determinate" value={100 - (100 / autoReloadSeconds) * remaining} />
                        </Box>
                        <Typography>{remaining}s</Typography>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => window.location.reload()} variant="contained">
                    {t("action.reload")}
                </Button>
                <Button onClick={() => setPostponed(true)} variant="outlined">
                    {t("action.postpone", { minutes: postponeMinutes })}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
