import { Box, IconButton, Tooltip } from "@mui/material"
import { ReactElement, cloneElement } from "react"

export interface ActionButtonProps {
    tooltip: string
    icon: ReactElement
    color: "inherit" | "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning"
    disabled?: boolean
    onClick: () => void
}

export const ActionButton = (props: ActionButtonProps) => {
    const { tooltip, icon, color, disabled, onClick } = props

    return (
        <Tooltip title={tooltip} disableInteractive>
            <Box>
                <IconButton color={color} onClick={onClick} disabled={disabled} sx={{ padding: "4px", margin: "0px" }}>
                    {cloneElement(icon, { fontSize: "small" })}
                </IconButton>
            </Box>
        </Tooltip>
    )
}
