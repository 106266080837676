import { Dialog } from "@mui/material"
import { StaticDateTimePicker } from "@mui/x-date-pickers"
import { DateTime } from "luxon"

export interface DateTimePickerDialogProps {
    initialValue: Date
    parent?: Element
    onPicked: (value: Date) => void
    onClose: () => void
}

export const DateTimePickerDialog = (props: DateTimePickerDialogProps) => {
    const { initialValue, parent, onPicked, onClose } = props

    return (
        <Dialog open onAbort={onClose} onClose={onClose} container={parent}>
            <StaticDateTimePicker
                defaultValue={DateTime.fromJSDate(initialValue)}
                onAccept={(value) => {
                    if (!value) {
                        return
                    }
                    onPicked(value.toJSDate())
                    onClose()
                }}
                ampm={false}
                disableFuture
                displayStaticWrapperAs="desktop"
                showDaysOutsideCurrentMonth
                slotProps={{
                    actionBar: { actions: [] },
                    previousIconButton: {
                        sx: { marginRight: "-12px" },
                    },
                    nextIconButton: {
                        sx: { marginLeft: "-12px" },
                    },
                }}
            />
        </Dialog>
    )
}
