export const setAdd = <T>(key: T) => {
    return (s: Set<T>) => {
        const result = new Set(s)
        result.add(key)
        return result
    }
}

export const setDel = <T>(key: T) => {
    return (s: Set<T>) => {
        const result = new Set(s)
        result.delete(key)
        return result
    }
}

export const equalSets = <T>(s1: Set<T>, s2: Set<T>) => {
    if (s1.size !== s2.size) {
        return false
    }
    const it = s1.keys()
    while (true) {
        const { value, done } = it.next()
        if (done) {
            return true
        }
        if (!s2.has(value)) {
            return false
        }
    }
}
