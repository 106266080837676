import { CameraAlt, CellTower, Close, DoneAll, LocationOn } from "@mui/icons-material"
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import {
    CameraScope,
    isAllScope,
    Scope,
    SiteScope,
    toggleAllCameras,
    toggleAllSites,
    toggleAllUnits,
    toggleCamera,
    toggleSite,
    toggleUnit,
    UnitScope,
} from "../../api/Authz"
import { Customer, Site, Unit } from "../../api/Customer"
import { IconText } from "./IconText"

export interface EditScopeDialogProps {
    initialScope: Scope
    customer: Customer
    sites: Map<number, Site>
    units: Map<string, Unit>
    onSave: (s: Scope) => void
    onClose: () => void
}

export function EditScopeDialog(props: EditScopeDialogProps) {
    const { initialScope, customer, sites, units, onSave, onClose } = props

    const [scope, setScope] = useState(initialScope)

    const { t } = useTranslation()

    const siteUnits = useMemo(
        () =>
            new Map(
                Array.from(sites.values()).map((s) => [
                    s.ID,
                    Array.from(units.values()).filter((u) => u.SiteID === s.ID),
                ])
            ),
        [units, sites]
    )

    const doSave = () => {
        onSave(scope)
        onClose()
    }

    const doToggleAllSites = () => setScope((s) => toggleAllSites(s, customer.ID))
    const doToggleSite = (siteID: number) => setScope((s) => toggleSite(s, customer.ID, siteID))
    const doToggleAllUnits = (siteID: number) => setScope((s) => toggleAllUnits(s, customer.ID, siteID))
    const doToggleUnit = (siteID: number, unit: string) => setScope((s) => toggleUnit(s, customer.ID, siteID, unit))
    const doToggleAllCameras = (siteID: number, unit: string) =>
        setScope((s) => toggleAllCameras(s, customer.ID, siteID, unit))
    const doToggleCamera = (siteID: number, unit: string, cameraID: number) =>
        setScope((s) => toggleCamera(s, customer.ID, siteID, unit, cameraID))

    const renderScope = () => {
        if (scope.customers?.length !== 1) {
            return <Typography>invalid customer scope, expects singular customer scope</Typography>
        }
        const c = scope.customers[0]
        if (c.all) {
            return <Typography>invalid customer scope, matches all customers</Typography>
        }
        return renderSites(c.sites || [])
    }

    const renderSites = (scope: SiteScope[]) => {
        const allSites = isAllScope(scope)

        return (
            <Stack>
                <Stack key={"all-sites"}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Checkbox size="small" checked={allSites} onClick={doToggleAllSites} />
                        <IconText icon={<DoneAll />} text={t("field.all_sites")} italic />
                    </Stack>
                </Stack>
                {!allSites &&
                    Array.from(sites.values()).map((site) => (
                        <Stack key={site.ID}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Checkbox
                                    checked={scope.some((s) => s.id === "" + site.ID)}
                                    size="small"
                                    onClick={() => doToggleSite(site.ID)}
                                />
                                <IconText icon={<LocationOn />} text={site.DisplayName} bold />
                            </Stack>
                            {scope.some((s) => s.id === "" + site.ID) &&
                                renderUnits(scope.find((s) => s?.id === "" + site.ID)?.units || [], site)}
                        </Stack>
                    ))}
                {!allSites && sites.size === 0 && (
                    <Stack key={"no-sites"}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Checkbox size="small" sx={{ visibility: "hidden" }} />
                            <IconText icon={<Close />} text={t("field.no_sites")} italic />
                        </Stack>
                    </Stack>
                )}
            </Stack>
        )
    }

    const renderUnits = (scope: UnitScope[], site: Site) => {
        const allUnits = isAllScope(scope)

        return (
            <Stack pl={3}>
                <Stack key={"all-units"}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Checkbox size="small" checked={allUnits} onClick={() => doToggleAllUnits(site.ID)} />
                        <IconText icon={<DoneAll />} text={t("field.all_units")} italic />
                    </Stack>
                </Stack>
                {!allUnits &&
                    siteUnits.get(site.ID)?.map((unit) => (
                        <Stack key={unit.ShortName}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Checkbox
                                    checked={scope.some((s) => s.shortName === unit.ShortName)}
                                    size="small"
                                    onClick={() => doToggleUnit(site.ID, unit.ShortName)}
                                />
                                <IconText icon={<CellTower />} text={unit.DisplayName} />
                            </Stack>
                            {scope.some((s) => s.shortName === unit.ShortName) &&
                                renderCameras(
                                    scope.find((u) => u?.shortName === unit.ShortName)?.cameras || [],
                                    site,
                                    unit
                                )}
                        </Stack>
                    ))}
                {!allUnits && siteUnits.get(site.ID)?.length === 0 && (
                    <Stack key={"no-units"}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Checkbox size="small" sx={{ visibility: "hidden" }} />
                            <IconText icon={<Close />} text={t("field.no_units")} italic />
                        </Stack>
                    </Stack>
                )}
            </Stack>
        )
    }

    const renderCameras = (scope: CameraScope[], site: Site, unit: Unit) => {
        const allCameras = isAllScope(scope)

        return (
            <Stack pl={3}>
                <Stack key={"all-units"}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Checkbox
                            size="small"
                            checked={allCameras}
                            onClick={() => doToggleAllCameras(site.ID, unit.ShortName)}
                        />
                        <IconText icon={<DoneAll />} text={t("field.all_cameras")} italic />
                    </Stack>
                </Stack>
                {!allCameras &&
                    unit.UnitConfig.Cameras?.map((camera) => (
                        <Stack key={camera.ID}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Checkbox
                                    checked={scope.some((s) => s.id === camera.ID + "")}
                                    size="small"
                                    onClick={() => doToggleCamera(site.ID, unit.ShortName, camera.ID)}
                                />
                                <IconText icon={<CameraAlt />} text={t("camera.nth_camera", { id: camera.ID })} />
                            </Stack>
                        </Stack>
                    ))}
                {!allCameras && (unit.UnitConfig.Cameras || []).length === 0 && (
                    <Stack key={"no-cameras"}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Checkbox size="small" sx={{ visibility: "hidden" }} />
                            <IconText icon={<Close />} text={t("field.no_cameras")} italic />
                        </Stack>
                    </Stack>
                )}
            </Stack>
        )
    }

    return (
        <Dialog onClose={onClose} aria-labelledby="edit-scope-dialog-title" open={true}>
            <DialogTitle id="edit-scope-dialog-title">{t("dialog.permission.edit_scope_title")}</DialogTitle>
            <Divider />
            <DialogContent>{renderScope()}</DialogContent>
            <Divider />
            <DialogActions sx={{ px: 2 }}>
                <Button onClick={doSave} variant="contained" color="primary">
                    {t("action.save")}
                </Button>
                <Button onClick={onClose} variant="contained" color="primary">
                    {t("action.cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
