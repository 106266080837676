import { CellTower, Hub, MapsHomeWork, Store } from "@mui/icons-material"
import { useTheme } from "@mui/material"
import { Unit, UnitType } from "../api/Customer"

export interface UnitIconProps {
    unit: Unit
}

export const UnitIcon = (props: UnitIconProps) => {
    const { unit } = props
    const theme = useTheme()
    const htmlColor = theme.palette.text.secondary

    switch (unit.Type) {
        case UnitType.Heavy:
        case UnitType.Light:
        case UnitType.Micro:
        case UnitType.City:
            return <CellTower fontSize="small" htmlColor={htmlColor} />
        case UnitType.Hub:
            return <Hub fontSize="small" htmlColor={htmlColor} />
        case UnitType.Site:
            return <MapsHomeWork fontSize="small" htmlColor={htmlColor} />
        case UnitType.SmartOffice:
            return <Store fontSize="small" htmlColor={htmlColor} />
    }
}
