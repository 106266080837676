import { Box, Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next"
import { CurrentTime, Playback } from "../../api/Video"
import { ToLongLocalDateTime } from "../../config/time"

export type CurrentTimeBoxProps = {
    live: boolean
    playback: Playback
}

export function CurrentTimeBox(props: CurrentTimeBoxProps) {
    const { live, playback } = props
    const { t } = useTranslation()

    const color = live ? "rgb(60, 200, 60)" : "orange"
    return (
        <Tooltip
            title={t("current_date_time", { time: ToLongLocalDateTime(CurrentTime(playback)) })}
            disableInteractive
            PopperProps={{ disablePortal: true }}
        >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="end"
                width="100%"
                height="100%"
                sx={{
                    backgroundColor: color,
                    userSelect: "none",
                }}
                px={0.5}
                pt={0.3}
            />
        </Tooltip>
    )
}
