import { useCallback, useReducer } from "react"
import { EventFilter, NewFilter } from "../services/EventFilter"

type State = {
    filter: EventFilter
}

type Action = { type: "update"; filter: EventFilter; key: string }

function reducer(state: State, action: Action) {
    switch (action.type) {
        case "update":
            store(action.key, action.filter)
            return {
                ...state,
                filter: action.filter,
            }
    }
}

type jsonFilter = {
    enabled?: boolean
    values?: string[] // Deprected, use ids instead.
    ids?: string[]
    includes?: string[]
    excludes?: string[]
}

const toStrings = (list: (string | RegExp)[]) => list.map((exp) => (exp instanceof RegExp ? `/${exp.source}/` : exp))

const fromStrings = (list: string[]) =>
    list.map((w) => (w.startsWith("/") && w.endsWith("/") ? new RegExp(w.slice(1, -1)) : w))

const store = (key: string, filter: EventFilter) => {
    const json = JSON.stringify({
        enabled: filter.enabled,
        ids: Array.from(filter.ids),
        includes: toStrings(filter.includes),
        excludes: toStrings(filter.excludes),
    })
    localStorage.setItem(key, json)
}

const load = (key: string, defaultFilter: EventFilter): EventFilter => {
    const stored = localStorage.getItem(key)
    if (!stored) {
        return defaultFilter
    }
    const parsed = JSON.parse(stored) as jsonFilter
    return NewFilter(
        !!parsed.enabled,
        new Set(parsed.ids || parsed.values || []),
        fromStrings(parsed.includes || []),
        fromStrings(parsed.excludes || [])
    )
}

export function useEventFilter(key: string, defaultFilter: EventFilter) {
    const [state, dispatch] = useReducer(reducer, {}, () => {
        return { filter: load(key, defaultFilter) }
    })

    const update = useCallback((filter: EventFilter) => dispatch({ type: "update", filter, key }), [dispatch, key])

    return { filter: state.filter, update }
}
