import { Box, Button, ListItemText, Menu, MenuItem, Tooltip, Typography, useTheme } from "@mui/material"
import { ReactElement, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Day, Hour, Minute } from "../../config/time"

export interface NumberChoice {
    label: string
    value: number
}

export const HalfSpeed = {
    label: "0.5x",
    value: 0.5,
}

export const NormalSpeed = {
    label: "1x",
    value: 1,
}

export const DoubleSpeed = {
    label: "2x",
    value: 2,
}

export const QuadrupleSpeed = {
    label: "4x",
    value: 4,
}

export const SixteenTimesSpeed = {
    label: "16x",
    value: 16,
}

export const ThirtyTwoTimesSpeed = {
    label: "32x",
    value: 32,
}

export const TenMinutesWindow = {
    label: "10m",
    value: 10 * Minute,
}

export const OneHourWindow = {
    label: "1h",
    value: 1 * Hour,
}

export const TwelveHoursWindow = {
    label: "12h",
    value: 12 * Hour,
}

export const OneDayWindow = {
    label: "1d",
    value: 1 * Day,
}

export const AsChoice = (value: number, choices: NumberChoice[]) => {
    return choices[AsIndex(value, choices)]
}

export const AsIndex = (value: number, choices: NumberChoice[]) => {
    const idx = choices.findIndex((v) => value === v.value)

    if (idx === -1) {
        return 0
    }
    return idx
}

export interface TimelineChoiceProps {
    tooltip: string
    disabled?: boolean
    icon: ReactElement
    choice: NumberChoice
    choices: NumberChoice[]
    parent?: Element
    onChange: (choice: NumberChoice) => void
}

export function TimelineChoice(props: TimelineChoiceProps) {
    const { tooltip, disabled, icon, choice, choices, parent, onChange } = props

    const { t } = useTranslation()
    const theme = useTheme()
    const anchorEl = useRef()
    const [open, setOpen] = useState(false)

    const onOpen = () => setOpen(true)
    const onClose = () => setOpen(false)
    const onPicked = (ch: NumberChoice) => {
        onClose()
        onChange(ch)
    }

    return (
        <Box ref={anchorEl}>
            <Tooltip title={t(tooltip)} disableInteractive>
                <Box>
                    <Button
                        variant="outlined"
                        sx={{
                            padding: "2px 4px 2px 8px",
                            minWidth: "64px",
                            backgroundColor: theme.palette.panel.background,
                            textAlign: "right",
                            textTransform: "none",
                        }}
                        disabled={disabled}
                        onClick={onOpen}
                        fullWidth
                        startIcon={icon}
                    >
                        <Typography
                            fontWeight="bold"
                            color={disabled ? theme.palette.action.disabled : undefined}
                            flexGrow={1}
                        >
                            {choice.label}
                        </Typography>
                    </Button>
                </Box>
            </Tooltip>
            <Menu
                keepMounted
                anchorEl={anchorEl.current}
                open={open}
                onClose={onClose}
                variant="selectedMenu"
                container={parent}
            >
                {choices.map((ch) => (
                    <MenuItem
                        key={"choice-" + ch.label}
                        onClick={() => onPicked(ch)}
                        selected={ch.value === choice.value}
                        dense
                    >
                        <ListItemText>{ch.label}</ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    )
}
