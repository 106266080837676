import { Typography } from "@mui/material"
import { useSearchParams } from "react-router-dom"
import { RedirectDialog } from "../components/ResetDialog"

export const Reset = () => {
    const [searchParams] = useSearchParams()
    const token = searchParams.get("token")
    const url = token ? atob(token) : ""

    if (!url) {
        return <Typography>Missing reset token.</Typography>
    }

    return <RedirectDialog url={url} />
}
