import {
    ControlCamera,
    Fullscreen,
    FullscreenExit,
    History,
    Lock,
    LockOpen,
    Settings,
    Videocam,
} from "@mui/icons-material"
import { Button, Divider, Link, Tooltip, Typography, useTheme } from "@mui/material"
import { Stack } from "@mui/system"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { DeviceInformation, DeviceStatus, InterfacesResponse, PrefixedIPv4Address } from "../../api/PTZ"
import { CameraState } from "./Camera"
import { CameraButton } from "./CameraButton"

export interface CameraTitleProps {
    cameraID: number
    unitName?: string
    deviceInfo: DeviceInformation | null
    cameraState: CameraState
    width?: number
    height?: number
    bitrate?: number
    fps?: number
    allowSettings: boolean
    allowPTZ: boolean
    allowFullscreen: boolean
    allowHistory: boolean
    allowPin: boolean
    toggledSettings: boolean
    toggledPTZ: boolean
    toggledFullscreen: boolean
    toggledHistory: boolean
    toggledPin: boolean
    onSettings: () => void
    onPTZ: () => void
    onFullscreen: () => void
    onHistory: () => void
    onPin: () => void
    onLoadInterfaces: () => Promise<InterfacesResponse>
}

const getCameraStateTitle = (state: CameraState) => {
    switch (state) {
        case CameraState.Offline:
            return "OFF"
        case CameraState.Connecting:
            return "LOADING"
        case CameraState.Connected:
            return "H264"
        default:
            return "UNKNOWN"
    }
}

const getCameraStateColor = (state: CameraState) => {
    switch (state) {
        case CameraState.Offline:
            return "rgb(150,150,150)"
        case CameraState.Connecting:
            return "rgb(150,50,70)"
        case CameraState.Connected:
            return "rgb(50,150,70)"
        default:
            return "rgb(150,50,70)"
    }
}

export function CameraTitle(props: CameraTitleProps) {
    const {
        cameraID,
        unitName,
        deviceInfo,
        cameraState,
        bitrate,
        fps,
        width,
        height,
        allowSettings,
        allowPTZ,
        allowFullscreen,
        allowHistory,
        allowPin,
        toggledSettings,
        toggledPTZ,
        toggledFullscreen,
        toggledHistory,
        toggledPin,
        onSettings,
        onPTZ,
        onFullscreen,
        onHistory,
        onPin,
        onLoadInterfaces,
    } = props

    const [interfaces, setInterfaces] = useState<InterfacesResponse | null>(null)

    const { t } = useTranslation()
    const theme = useTheme()

    const loadInterfaces = () =>
        onLoadInterfaces()
            .then(setInterfaces)
            .catch(() => setInterfaces(null))

    const renderAddress = (manual: boolean, adddress: PrefixedIPv4Address) => {
        const ip = adddress?.Address
        if (!ip) {
            return null
        }
        return (
            <Typography variant="body2">
                - {manual ? t("network.dhcp") : t("network.fixed")}:{" "}
                <Link target="_blank" href={(ip.startsWith("http") ? "" : "http://") + ip}>
                    {ip}
                </Link>
            </Typography>
        )
    }
    const renderDeviceInfo = (info: DeviceInformation | null, fps?: number, bitrate?: number) => (
        <Stack color={theme.palette.grey[300]} maxWidth="300px">
            <Typography variant="body2" fontWeight="bold">
                {t("camera.system_information")}
            </Typography>
            <Divider />
            {!!deviceInfo?.DisplayName && (
                <Typography variant="body2">
                    <b>{t("camera.display_name")}:</b> {deviceInfo.DisplayName}
                </Typography>
            )}
            {!!info?.Manufacturer && (
                <Typography variant="body2">
                    <b>{t("camera.manufacturer")}:</b> {info.Manufacturer}
                </Typography>
            )}
            {!!info?.Model && (
                <Typography variant="body2">
                    <b>{t("camera.model")}:</b> {info.Model}
                </Typography>
            )}
            {!!info?.FirmwareVersion && (
                <Typography variant="body2">
                    <b>{t("camera.firmware")}:</b> {info.FirmwareVersion}
                </Typography>
            )}
            {!!width && !!height && (
                <Typography variant="body2">
                    <b>{t("camera.resolution")}:</b> {`${width}x${height}`}
                </Typography>
            )}
            {!!fps && (
                <Typography variant="body2">
                    <b>{t("camera.fps")}:</b> {fps.toFixed(0)}
                </Typography>
            )}
            {!!bitrate && (
                <Typography variant="body2">
                    <b>{t("camera.bitrate")}:</b> {(bitrate / 1024).toFixed(0)}&nbsp;kbps
                </Typography>
            )}
            {info?.Status !== DeviceStatus.Online && !!info?.Error && (
                <Typography variant="body2" color={theme.palette.warning.main}>
                    <b>{t("error")}:</b> Failed {info.Error}
                </Typography>
            )}
            {interfaces ? (
                <>
                    <Typography variant="body2">
                        <b>{t("network.ip_addresses")}:</b>
                    </Typography>
                    {interfaces.NetworkInterfaces.map((i) => {
                        const dhcpAddress = i?.IPv4?.Config?.FromDHCP
                        const manualAddresses = i?.IPv4?.Config?.Manual
                        return (
                            <>
                                {renderAddress(true, dhcpAddress)}
                                {manualAddresses && manualAddresses.map((a) => renderAddress(false, a))}
                            </>
                        )
                    })}
                </>
            ) : (
                <Button onClick={loadInterfaces} size="small" variant="outlined">
                    {t("details")}
                </Button>
            )}
        </Stack>
    )

    return (
        <Stack
            py={0.3}
            px={0.5}
            spacing={0.5}
            direction="row"
            alignItems="center"
            position="absolute"
            width="100%"
            zIndex={20}
            sx={{ backgroundColor: theme.palette.panel.background }}
            overflow="hidden"
        >
            <Videocam fontSize="small" color="primary" />
            <Tooltip title={renderDeviceInfo(deviceInfo, fps, bitrate)}>
                <Typography fontSize="0.9em" textOverflow="ellipsis" noWrap flexShrink={1}>
                    {unitName && <b style={{ color: theme.palette.text.secondary }}>{`${unitName.toUpperCase()} `}</b>}
                    <b>Cam{cameraID}</b>
                    {deviceInfo?.DisplayName && `: ${deviceInfo.DisplayName}`}
                </Typography>
            </Tooltip>
            <Stack flexGrow={1} height="22px" direction="row" alignItems="center" position="relative" overflow="hidden">
                <Stack
                    spacing={0.5}
                    direction="row"
                    alignItems="center"
                    overflow="hidden"
                    flexGrow={1}
                    flexBasis={0}
                    sx={{
                        overflowX: "hidden",
                        position: "absolute",
                        right: 0,
                    }}
                >
                    {bitrate !== undefined && cameraState === CameraState.Connected && (
                        <Typography fontSize="0.7em" textOverflow="clip" noWrap color={theme.palette.grey[700]}>
                            {(bitrate / 1024).toFixed(0)}&nbsp;kbps
                        </Typography>
                    )}
                    {fps !== undefined && cameraState === CameraState.Connected && (
                        <Typography fontSize="0.7em" textOverflow="clip" noWrap color={theme.palette.grey[700]}>
                            {fps.toFixed(0)}&nbsp;fps
                        </Typography>
                    )}
                    {deviceInfo?.Model !== undefined && (
                        <Typography fontSize="0.7em" textOverflow="ellipsis" noWrap color={theme.palette.grey[700]}>
                            {deviceInfo?.Model}
                        </Typography>
                    )}
                    <Typography
                        fontSize="0.7em"
                        textOverflow="clip"
                        noWrap
                        color={getCameraStateColor(cameraState)}
                        fontWeight="bold"
                        textAlign="right"
                    >
                        {getCameraStateTitle(cameraState)}
                    </Typography>
                </Stack>
            </Stack>
            <CameraButton
                tooltip={toggledHistory ? "camera.archive_disable_tooltip" : "camera.archive_enable_tooltip"}
                toggled={toggledHistory}
                disabled={!allowHistory}
                onClick={onHistory}
                icon={<History />}
            />
            <CameraButton
                tooltip={toggledFullscreen ? "camera.fullscreen_disable_tooltip" : "camera.fullscreen_enable_tooltip"}
                toggled={toggledFullscreen}
                disabled={!allowFullscreen}
                onClick={onFullscreen}
                icon={toggledFullscreen ? <FullscreenExit /> : <Fullscreen />}
            />
            <CameraButton
                tooltip={toggledSettings ? "camera.settings_disable_tooltip" : "camera.settings_enable_tooltip"}
                toggled={toggledSettings}
                disabled={!allowSettings}
                onClick={onSettings}
                icon={<Settings />}
            />
            <CameraButton
                tooltip={toggledPTZ ? "camera.ptz_disable_tooltip" : "camera.ptz_enable_tooltip"}
                toggled={toggledPTZ}
                disabled={!allowPTZ}
                onClick={onPTZ}
                icon={<ControlCamera />}
            />
            <CameraButton
                tooltip={toggledPin ? "camera.pin_disable_tooltip" : "camera.pin_enable_tooltip"}
                toggled={toggledPin}
                disabled={!allowPin}
                onClick={onPin}
                icon={<LockOpen />}
                toggledIcon={<Lock />}
                toggledColor={theme.palette.warning.main}
            />
        </Stack>
    )
}
