import { Dangerous } from "@mui/icons-material"
import { Stack, TableCell, TableRow, Typography, useTheme } from "@mui/material"
import { SerializedError } from "@reduxjs/toolkit"
import { FetchBaseQueryError } from "@reduxjs/toolkit/query"
import { useTranslation } from "react-i18next"

export interface ErrorRowProps {
    error: FetchBaseQueryError | SerializedError
}

export const ErrorRow = (props: ErrorRowProps) => {
    const { error } = props

    const theme = useTheme()
    const { t } = useTranslation()

    return (
        <TableRow>
            <TableCell colSpan={99999}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Dangerous color="error" />
                    <Typography variant="body2" fontStyle="italic" color={theme.palette.error.main}>
                        {t("error")}: {"data" in error ? (error.data as string) : "unknown"}
                    </Typography>
                </Stack>
            </TableCell>
        </TableRow>
    )
}
