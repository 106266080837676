import { Save } from "@mui/icons-material"
import { Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography, useTheme } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import { User } from "../api/User"
import { validatePassword } from "../widgets/validation"
import { ETDialogTitle } from "./ETDialogTitle"

export interface SetPasswordDialogProps {
    actor?: User
    messages: string[]
    onSetPassword: (password: string) => void
}

export const SetPasswordDialog = (props: SetPasswordDialogProps) => {
    const { actor, messages, onSetPassword } = props
    const [password, setPassword] = React.useState("")
    const [passwordError, setPasswordError] = React.useState("")

    const { t } = useTranslation()
    const theme = useTheme()

    const hasError = () => !checkPassword(password)

    const onClickSave = () => {
        if (hasError()) {
            return
        }
        onSetPassword(password)
    }

    const setPasswordChecked = (s: string) => {
        setPassword(s)
        checkPassword(s)
    }
    const checkPassword = (s?: string) => validatePassword(s, t, setPasswordError)

    return (
        <Dialog aria-labelledby="set-password-dialog-title" maxWidth="xs" open fullWidth>
            <ETDialogTitle />
            <DialogContent>
                <Grid container spacing={theme.spacing(1)}>
                    <Grid item xs={12}>
                        <Typography>{t("message.pick_new_password")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="Username"
                            label={t("field.username")}
                            autoComplete="username"
                            autoCorrect="off"
                            autoCapitalize="none"
                            defaultValue={actor?.Username}
                            disabled
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="Password"
                            label={t("field.new_password")}
                            autoComplete="new-password"
                            autoCorrect="off"
                            autoCapitalize="none"
                            type="password"
                            error={!!passwordError}
                            helperText={passwordError}
                            onChange={(event) => setPasswordChecked(event.target.value)}
                            fullWidth={true}
                        />
                    </Grid>
                    <>
                        {messages.map((message, i) => (
                            <Grid key={i} item xs={12}>
                                <Typography color={theme.palette.error.main}>{message}</Typography>
                            </Grid>
                        ))}
                    </>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button startIcon={<Save />} onClick={onClickSave} variant="contained" color="primary">
                    {t("action.save")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
