import {
    KeyboardArrowDown,
    KeyboardArrowUp,
    KeyboardDoubleArrowDown,
    KeyboardDoubleArrowUp,
    SettingsInputComponent,
} from "@mui/icons-material"
import { Box, Grid, IconButton, Tooltip, Typography, useTheme } from "@mui/material"
import { Stack } from "@mui/system"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { Operation } from "../../api/Authz"
import { SendCommandRequest } from "../../api/Command"
import { Site, Unit } from "../../api/Customer"
import { Event, Labels } from "../../api/Event"
import { useUnitPermission } from "../../auth/AuthorizerProvider"
import { http, noSnackBar } from "../../backend/request"
import { request } from "../../config/headers"
import { monoEndpointURL } from "../../config/urls"
import { itemKey, Thing, useAlarmEvents } from "../../services/AlarmEvents"
import { allEvents } from "../../services/EventStream"
import { ConnectionStatus } from "../common/ConnectionStatus"
import { LightInfo } from "./LightInfo"
import { OutputInfo } from "./OutputInfo"
import { PartitionBlock } from "./PartitionBlock"

const regexAlarmThingName = /[a-zA-Z0-9]+\.alarm_[0-9]+\.(zone|partition|output)_([0-9]+)/
const regexLightThingName = /[a-zA-Z0-9]+\.light_([0-9]+)/

export interface Partition {
    id: number
    displayName?: string
    thingName: string
    labels: Labels
    alarmID: number
    isSystem: boolean
}

export interface Zone {
    id: number
    displayName?: string
    thingName: string
    labels: Labels
    partitionID: number
    alarmID: number
    isSystem: boolean
}

export interface Output {
    id: number
    displayName?: string
    thingName: string
    labels: Labels
    alarmID: number
    type?: string
}

export interface Light {
    id: number
    displayName?: string
    thingName: string
}

export interface State {
    alarm?: boolean
    alarmInMemory?: boolean
    violation?: boolean
    lowBattery?: boolean
    disconnected?: boolean
    bypass?: boolean
    armed?: boolean
    switch?: boolean
}

export interface AlarmProps {
    site: Site
    unit: Unit
}

const upsertSorted = (array: any[], newElement: any): any[] => {
    const index = array.findIndex((element) => element.id >= newElement.id)
    if (index === -1) {
        return [...array, newElement]
    } else if (array[index].id === newElement.id) {
        return [...array.slice(0, index), newElement, ...array.slice(index + 1)]
    } else {
        return [...array.slice(0, index), newElement, ...array.slice(index)]
    }
}

const addOrUpdateState = (id: string, newState: boolean, prev: Map<string, boolean>) => {
    const newStates = new Map(prev)
    newStates.set(id, newState)
    return newStates
}

export function NewAlarm(props: AlarmProps) {
    const { site, unit } = props
    const [partitions, setPartitions] = useState<Partition[]>([])
    const [zones, setZones] = useState<Zone[]>([])
    const [outputs, setOutputs] = useState<Output[]>([])
    const [lights, setLights] = useState<Light[]>([])
    const [states, setStates] = useState<Map<string, boolean>>(new Map())
    const [showOutputs, setShowOutputs] = useState(false)
    const [showLights, setShowLights] = useState(true)
    const [showDetails, setShowDetails] = useState(new Set())

    const { t } = useTranslation()
    const theme = useTheme()

    const allowLightOperation = useUnitPermission(Operation.SWITCH_LIGHT, unit)

    const flipDetails = (partitionID: number) =>
        setShowDetails((v) => {
            const r = new Set(v)
            if (r.has(partitionID)) {
                r.delete(partitionID)
            } else {
                r.add(partitionID)
            }
            return r
        })

    const onThing = useCallback((thing: Thing) => {
        const lightMatches = thing.thing.match(regexLightThingName)
        if (lightMatches) {
            setLights((prev) =>
                upsertSorted(prev, {
                    id: +lightMatches[1],
                    displayName: thing.annotations?.display_name,
                    thingName: thing.thing,
                })
            )
            return
        }

        const matches = thing.thing.match(regexAlarmThingName)
        if (!matches) {
            return
        }

        const id = +matches[2]
        const isSystem = thing.labels.get("member_system") === "true"
        const alarm = thing.labels.get("alarm")
        const thingClass = thing.labels.get("class")
        if (!alarm || !thingClass) {
            return
        }
        const alarmID = +alarm

        switch (thingClass) {
            case "alarm_partition": {
                setPartitions((prev) =>
                    upsertSorted(prev, {
                        id: id,
                        displayName: thing.annotations?.display_name,
                        thingName: thing.thing,
                        isSystem: isSystem,
                        labels: thing.labels,
                        alarmID: alarmID,
                    })
                )
                break
            }
            case "alarm_zone": {
                const partitionID = thing.labels.get("partition")
                if (!partitionID) {
                    break
                }
                setZones((prev) =>
                    upsertSorted(prev, {
                        id: id,
                        displayName: thing.annotations?.display_name,
                        thingName: thing.thing,
                        isSystem: isSystem,
                        labels: thing.labels,
                        partitionID: parseInt(partitionID, 10),
                        alarmID: alarmID,
                    })
                )
                break
            }
            case "alarm_output": {
                setOutputs((prev) =>
                    upsertSorted(prev, {
                        id: id,
                        displayName: thing.annotations?.display_name,
                        thingName: thing.thing,
                        labels: thing.labels,
                        alarmID: alarmID,
                        type: thing.annotations?.output_type,
                    })
                )
                break
            }
            default:
                return
        }
    }, [])

    const onEvent = useCallback((event: Event) => {
        if (!event.thing.match(regexAlarmThingName) && !event.thing.match(regexLightThingName)) {
            return
        }
        const value = !!event.value
        setStates((prev) => addOrUpdateState(itemKey(event.thing, event.item), value, prev))
    }, [])

    const { online } = useAlarmEvents(site.ID, unit.ShortName, true, true, allEvents, onThing, onEvent)

    const sendCommand = useCallback(
        (item: string, value: number, thing: string) => {
            const command: SendCommandRequest = {
                command: {
                    thing: thing,
                    item: item,
                    command: {
                        number: value,
                    },
                },
            }
            http(`Sending command`, monoEndpointURL(`sites/${site.ID}/commands`), noSnackBar, {
                method: "POST",
                headers: request.headers,
                body: JSON.stringify(command),
            })
                .then((_) => console.log("Successfuly set item"))
                .catch((e) => console.log(e))
        },
        [site.ID]
    )

    const flipArmed = useCallback(
        (armed: boolean, partition: Partition) => sendCommand("armed", armed ? 0 : 1, partition.thingName),
        [sendCommand]
    )

    const flipBypass = useCallback(
        (bypass: boolean, zone: Zone) => sendCommand("bypass", bypass ? 0 : 1, zone.thingName),
        [sendCommand]
    )

    const flipOutputSwitch = useCallback(
        (switchOn: boolean, output: Output) => sendCommand("switch", switchOn ? 0 : 1, output.thingName),
        [sendCommand]
    )

    const flipLightSwitch = useCallback(
        (switchOn: boolean, light: Light) => sendCommand("switch", switchOn ? 0 : 1, light.thingName),
        [sendCommand]
    )

    const partitionState = useCallback(
        (partition: Partition, item: string) => !!states.get(itemKey(partition.thingName, item)),
        [states]
    )
    const zoneState = useCallback((zone: Zone, item: string) => states.get(itemKey(zone.thingName, item)), [states])
    const outputState = useCallback((output: Output) => states.get(itemKey(output.thingName, "switch")), [states])
    const lightState = useCallback((light: Light) => states.get(itemKey(light.thingName, "switch")), [states])

    const showAll = showDetails.size === partitions.length && showOutputs && showLights

    return (
        <Stack sx={{ py: 2, px: 4, alignItems: "center", width: "100%" }}>
            <Box sx={{ width: "min(100%,1280px)" }}>
                <ConnectionStatus online={online} />
                <Grid container pb={5} spacing={1}>
                    <>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Stack direction="row" alignItems="center" pb={2}>
                                <Typography variant="h5" sx={{ flexGrow: 1 }}>
                                    {t("offering.alarm")}
                                </Typography>
                                <Box flexGrow={1} />
                                <Tooltip
                                    title={t(showAll ? "alarm.hide_all_tooltip" : "alarm.show_all_tooltip")}
                                    disableInteractive
                                >
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            if (showAll) {
                                                setShowOutputs(false)
                                                setShowLights(false)
                                                setShowDetails(new Set())
                                            } else {
                                                setShowOutputs(true)
                                                setShowLights(true)
                                                setShowDetails(new Set(partitions.map((p) => p.id)))
                                            }
                                        }}
                                    >
                                        {showAll ? <KeyboardDoubleArrowUp /> : <KeyboardDoubleArrowDown />}
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} md={8} lg={9} />
                        {partitions.map((partition) => (
                            <Grid
                                key={partition.id}
                                item
                                xs={12}
                                sm={showDetails.has(partition.id) ? 12 : 6}
                                md={showDetails.has(partition.id) ? 12 : 4}
                                lg={showDetails.has(partition.id) ? 12 : 3}
                            >
                                <PartitionBlock
                                    partition={partition}
                                    zones={zones}
                                    unit={unit}
                                    armed={partitionState(partition, "armed")}
                                    alarm={partitionState(partition, "alarm")}
                                    alarmInMemory={partitionState(partition, "alarm_in_memory")}
                                    showDetails={showDetails.has(partition.id)}
                                    zoneState={zoneState}
                                    flipArmed={(on) => flipArmed(on, partition)}
                                    flipBypass={flipBypass}
                                    flipDetails={() => flipDetails(partition.id)}
                                />
                            </Grid>
                        ))}

                        {outputs.length > 0 && (
                            <Grid item xs={12}>
                                <Grid container spacing={1} pb={5}>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <SettingsInputComponent htmlColor={theme.palette.text.secondary} />
                                            <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                                {t("alarm.outputs")}
                                            </Typography>
                                            <Box flexGrow={1} />
                                            <Tooltip
                                                title={t(
                                                    showOutputs
                                                        ? "alarm.hide_outputs_tooltip"
                                                        : "alarm.show_outputs_tooltip"
                                                )}
                                                disableInteractive
                                            >
                                                <IconButton color="primary" onClick={() => setShowOutputs((s) => !s)}>
                                                    {showOutputs ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={8} lg={9} />
                                    {showOutputs &&
                                        outputs.map((output) => (
                                            <OutputInfo
                                                key={output.id}
                                                id={output.id}
                                                displayName={output.displayName}
                                                type={output.type}
                                                unit={unit}
                                                alarmID={output.alarmID}
                                                value={outputState(output)}
                                                flipSwitch={(on) => flipOutputSwitch(on, output)}
                                            />
                                        ))}
                                </Grid>
                            </Grid>
                        )}

                        {lights.length > 0 && (
                            <Grid item xs={12}>
                                <Grid container spacing={1} pb={5}>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <SettingsInputComponent htmlColor={theme.palette.text.secondary} />
                                            <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                                {t("unit.lights")}
                                            </Typography>
                                            <Box flexGrow={1} />
                                            <Tooltip
                                                title={t(
                                                    showLights ? "unit.hide_lights_tooltip" : "unit.show_lights_tooltip"
                                                )}
                                                disableInteractive
                                            >
                                                <IconButton color="primary" onClick={() => setShowLights((s) => !s)}>
                                                    {showLights ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={8} lg={9} />
                                    {showLights &&
                                        lights.map((light) => (
                                            <LightInfo
                                                key={light.id}
                                                id={light.id}
                                                displayName={light.displayName}
                                                value={lightState(light)}
                                                allowed={allowLightOperation}
                                                flipSwitch={(on) => flipLightSwitch(on, light)}
                                            />
                                        ))}
                                </Grid>
                            </Grid>
                        )}
                    </>
                </Grid>
            </Box>
        </Stack>
    )
}
