import { Typography, useTheme } from "@mui/material"
import { Stack } from "@mui/system"
import { useSnackbar } from "notistack"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from "recharts"
import { Unit } from "../../api/Customer"
import { DataPoints, StateON } from "../../api/OpenHAB"
import { http } from "../../backend/request"
import { hookInterval } from "../../config/time"
import { openHabEndpointURL } from "../../config/urls"

interface Value {
    time: number
    value: number
}

const INTERVAL = 5 * 60 * 1000
const yesterday = () => new Date().getTime() - 24 * 60 * 60 * 1000

const createQueryURL = (unit: Unit, itemName: string) =>
    openHabEndpointURL(
        unit,
        `persistence/items/${itemName}?boundary=true&starttime=${new Date(yesterday()).toISOString()}`
    )

const toShortLocalTime = (v: any) =>
    new Date(v).toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
    })

const toLocalDateTime = (v: any) =>
    new Date(v).toLocaleDateString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
    })

const pushValueInMinutes = (prev: number, current: Value, interval: number, till: number, target: Value[]) => {
    if (prev >= current.time) {
        current.time = prev + 1
    }
    target.push(current)

    let now = current.time + interval
    let last = current

    while (now < till) {
        last = {
            time: now,
            value: last.value,
        }
        target.push(last)
        now += interval
    }
    return last
}

const extendData = (data: Value[]) => {
    if (data.length === 0) {
        return data
    }

    const result: Value[] = []
    const end = new Date().getTime()
    let last = data[0]

    for (let i = 0; i < data.length; i++) {
        const next = i + 1
        const nextTime = next < data.length ? data[next].time : end
        last = pushValueInMinutes(last.time, data[i], INTERVAL, nextTime, result)
    }
    console.log("loaded", data)
    console.log("extended", result)
    return result
}

export interface SwitchHistoryChartProps {
    label: string
    unit: Unit
    item: string
    color: string
    showCount?: boolean
}

export function SwitchHistoryChart(props: SwitchHistoryChartProps) {
    const { label, unit, item, color, showCount } = props

    const [data, setData] = useState<Value[]>([])

    const snackbar = useSnackbar()
    const theme = useTheme()

    const reloadData = useCallback(() => {
        http<DataPoints>("Getting timeseries", createQueryURL(unit, item), snackbar)
            .then((result) =>
                setData(
                    extendData(
                        result.data.map((d) => {
                            return {
                                time: d.time,
                                value: d.state === StateON ? 1 : 0,
                            }
                        })
                    )
                )
            )
            .catch((e) => console.log(e))
    }, [snackbar, unit, item])

    useEffect(() => reloadData(), [reloadData])

    useEffect(() => hookInterval(() => reloadData(), INTERVAL), [reloadData])

    const countON = useMemo(() => {
        let last = false
        let count = 0

        for (let i = 0; i < data.length; i++) {
            const cur = data[i].value === 1
            if (!last && cur) {
                count++
            }
            last = cur
        }
        return count
    }, [data])

    return (
        <Stack
            sx={{
                backgroundColor: theme.palette.background.paper,
                width: "100%",
                height: "80px",
            }}
            p={1}
        >
            <Typography>
                {label}
                {showCount ? ` (${countON})` : ""}
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={data}>
                    <Tooltip
                        isAnimationActive={false}
                        contentStyle={{ backgroundColor: theme.palette.grey[800], color: theme.palette.grey[50] }}
                        itemStyle={{ color: theme.palette.primary.main }}
                        labelFormatter={toLocalDateTime}
                        formatter={(s: any) => (s ? "ON" : "OFF")}
                        cursor={false}
                    />
                    <XAxis dataKey="time" fontSize="small" scale="time" tickFormatter={toShortLocalTime} height={14} />
                    <Area
                        isAnimationActive={false}
                        type="stepAfter"
                        dataKey="value"
                        name="State"
                        fill={color}
                        fillOpacity={1}
                        stroke={color}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </Stack>
    )
}
