import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { Operation } from "../api/Authz"
import { Customer, Unit } from "../api/Customer"
import { WhoAmI } from "../api/WhoAmI"
import { useUnitPermission } from "../auth/AuthorizerProvider"
import { UnitPage } from "../components/UnitPage"
import { Tracking } from "../features/tracking/Tracking"

export interface UnitTrackingProps {
    whoAmI: WhoAmI
    customers: Customer[]
    showArchived: boolean
    setShowArchived: (value: boolean) => void
}

export function UnitTracking(props: UnitTrackingProps) {
    const { whoAmI, customers, showArchived, setShowArchived } = props

    const { shortName } = useParams()

    const unit = useMemo(
        () => customers.flatMap((c) => c.Units).find((u) => u.ShortName === shortName),
        [customers, shortName]
    )
    const site = useMemo(() => customers.flatMap((c) => c.Sites).find((s) => s.ID === unit?.SiteID), [customers, unit])
    const customer = useMemo(() => customers.find((c) => c.ID === site?.CustomerID), [customers, site])
    const allowed = useUnitPermission(Operation.UI_VIEW_TRACKING, unit)

    return (
        <UnitPage
            whoAmI={whoAmI}
            unit={unit}
            site={site}
            customer={customer}
            customers={customers}
            allowed={allowed}
            showArchived={showArchived}
            setShowArchived={setShowArchived}
            render={(unit: Unit) => <Tracking unit={unit} />}
        />
    )
}
