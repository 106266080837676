import { LatLng } from "leaflet"

export interface GPS {
    CreatedAt: string
    Latitude: number
    Longitude: number
}

export const toLeafletPosition = (position: GPS) => {
    return new LatLng(position.Latitude, position.Longitude)
}

export const formatGPS = (position: GPS) => {
    return `${position.Latitude.toFixed(5).toString()}, ${position.Longitude.toFixed(5).toString()}`
}
