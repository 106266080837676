import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { request } from "../../config/headers"
import { endpointURL } from "../../config/urls"
import { GroupUser, ListCustomerGroupUsersResponse } from "../Group"

export const groupUsersApi = createApi({
    reducerPath: "groupUsersApi",
    baseQuery: fetchBaseQuery({ ...request, baseUrl: endpointURL("") }),
    tagTypes: ["GroupUsers"],
    endpoints: (builder) => ({
        getGroupUsers: builder.query<GroupUser[], number>({
            query: (groupID) => `groups/${groupID}/users`,
            transformResponse: (response: ListCustomerGroupUsersResponse) => response.data || [],
            providesTags: [{ type: "GroupUsers", id: "LIST" }],
        }),
    }),
})

export const { useGetGroupUsersQuery } = groupUsersApi
