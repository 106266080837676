import { useCallback, useReducer } from "react"

type State = {
    attempt: number
    lastAttempt: number
    timeout: NodeJS.Timeout | null
}

type Action = { type: "cancel" } | { type: "trigger" } | { type: "retry"; minWaitMillis: number; trigger: () => void }

function reducer(state: State, action: Action) {
    switch (action.type) {
        case "cancel":
            if (state.timeout !== null) {
                clearTimeout(state.timeout)
            }
            return {
                ...state,
                timeout: null,
            }
        case "trigger":
            return {
                ...state,
                lastAttempt: Date.now(),
                attempt: state.attempt + 1,
                timeout: null,
            }
        case "retry":
            if (state.timeout !== null) {
                // There is still a retry attempt scheduled in the future. Do nothing.
                return state
            }

            const now = Date.now()
            const elapsed = now - state.lastAttempt
            const retryWait = Math.max(0, action.minWaitMillis - elapsed)
            const triggerTime = now + retryWait

            if (retryWait > 0) {
                // We should delay the triggering.
                return {
                    ...state,
                    timeout: setTimeout(action.trigger, retryWait),
                }
            }

            return {
                ...state,
                lastAttempt: triggerTime,
                attempt: state.attempt + 1,
                timeout: null,
            }
    }
}

export function useBackoff(minWaitMillis: number) {
    const [state, dispatch] = useReducer(reducer, {
        attempt: 0,
        lastAttempt: Date.now(),
        timeout: null,
    })

    const trigger = useCallback(() => dispatch({ type: "trigger" }), [])
    const cancel = useCallback(() => dispatch({ type: "cancel" }), [])
    const retry = useCallback(
        () =>
            dispatch({
                type: "retry",
                minWaitMillis: minWaitMillis,
                trigger: trigger,
            }),
        [trigger, minWaitMillis]
    )

    return { attempt: state.attempt, retry, cancel }
}
