import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { request } from "../../config/headers"
import { endpointURL } from "../../config/urls"
import { BaseRole, ListAllBaseRolesResponse } from "../Authz"

export const baseRoleApi = createApi({
    reducerPath: "baseRoleApi",
    baseQuery: fetchBaseQuery({ ...request, baseUrl: endpointURL("") }),
    tagTypes: ["BaseRoles"],
    endpoints: (builder) => ({
        getBaseRoles: builder.query<BaseRole[], void>({
            query: () => `base-roles`,
            transformResponse: (response: ListAllBaseRolesResponse) => response.data,
            providesTags: [{ type: "BaseRoles", id: "LIST" }],
        }),
    }),
})

export const { useGetBaseRolesQuery } = baseRoleApi
