import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, useTheme } from "@mui/material"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Preset } from "../api/PTZ"
import { validateNonEmpty } from "../widgets/validation"

export interface PresetDialogProps {
    open: boolean
    preset?: Preset
    onClose: () => void
    onCreate: (preset: Preset) => void
    onUpdate: (preset: Preset) => void
    parent?: Element
}

export function PresetDialog(props: PresetDialogProps) {
    const { open, preset, onClose, onCreate, onUpdate, parent } = props
    const [token, setToken] = React.useState(preset?.token)
    const [name, setName] = React.useState(preset?.Name)
    const [nameError, setNameError] = React.useState("")

    const { t } = useTranslation()
    const theme = useTheme()

    useEffect(() => {
        if (open) {
            clearError()
        }
    }, [open])

    useEffect(() => {
        setToken(preset?.token)
        setName(preset?.Name)
    }, [preset])

    const hasError = () => !checkName(name)
    const clearError = () => {
        setNameError("")
    }

    const onSave = () => {
        if (hasError()) {
            return
        }
        // Do save it here.
        const toSave: Preset = {
            token: token || "",
            Name: name || "",
        }
        if (!preset) {
            onCreate(toSave)
        } else {
            onUpdate(toSave)
        }
    }

    const setNameChecked = (s: string) => {
        setName(s)
        checkName(s)
    }
    const checkName = (s?: string) => validateNonEmpty(s, t, setNameError)

    return (
        <Dialog onClose={onClose} aria-labelledby="preset-dialog-title" maxWidth="xs" open={open} container={parent}>
            <DialogTitle id="preset-dialog-title">
                {t(preset ? "dialog.edit_preset.title" : "dialog.new_preset.title")}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={theme.spacing(2)}>
                    <Grid item xs={12}>
                        <TextField
                            id="Name"
                            label={t("field.name")}
                            autoComplete="off"
                            defaultValue={preset?.Name}
                            error={!!nameError}
                            helperText={nameError}
                            onChange={(event) => setNameChecked(event.target.value)}
                            fullWidth={true}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onSave} variant="contained" color="primary">
                    {t(preset ? "action.save" : "action.create")}
                </Button>
                <Button onClick={onClose} variant="contained" color="primary">
                    {t("action.cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
