import { Close } from "@mui/icons-material"
import { Paper, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Role } from "../../api/Authz"
import { Roles } from "../../api/portal/hooks/Roles"
import { IconText } from "./IconText"
import { RoleChip } from "./RoleChip"

export interface ImpliedRolesTooltipProps {
    impliedBy: Role[]
    roles: Roles
}

export const ImpliedRolesTooltip = (props: ImpliedRolesTooltipProps) => {
    const { impliedBy, roles } = props

    const { t } = useTranslation()

    return (
        <Stack m={1} mb={2} spacing={1} alignItems="flex-start">
            <Typography variant="body2">{t("message.role_implied_by")}</Typography>
            <Paper variant="outlined" sx={{ overflowY: "auto", maxHeight: 350 }}>
                <Stack m={1} spacing={1} alignItems="flex-start">
                    {impliedBy.length > 0 ? (
                        impliedBy.map((r) => <RoleChip key={r.id} role={r} roles={roles} />)
                    ) : (
                        <IconText icon={<Close />} text={t("message.no_roles")} italic />
                    )}
                </Stack>
            </Paper>
        </Stack>
    )
}
