import { GpsFixed, ImageAspectRatio, LinkedCamera } from "@mui/icons-material"
import { ListItemIcon, ListItemText, MenuItem, MenuList, Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import { disablePropagation } from "../map/Propagation"

export interface MainMenuProps {
    open: boolean
    admin: boolean
    onDevices: () => void
    onCameras: () => void
    onImageOverlays: () => void
}

export const MainMenu = (props: MainMenuProps) => {
    const { open, admin, onDevices, onCameras, onImageOverlays } = props

    const { t } = useTranslation()

    return (
        <>
            {open && (
                <div className="leaflet-bottom leaflet-left" ref={disablePropagation}>
                    <div className="leaflet-bar leaflet-control">
                        <Paper>
                            <MenuList>
                                <MenuItem onClick={onDevices}>
                                    <ListItemIcon>
                                        <GpsFixed fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary={t("tracking.targets")} />
                                </MenuItem>
                                <MenuItem onClick={onCameras}>
                                    <ListItemIcon>
                                        <LinkedCamera fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary={t("tracking.cameras")} />
                                </MenuItem>
                                {admin && (
                                    <MenuItem onClick={onImageOverlays}>
                                        <ListItemIcon>
                                            <ImageAspectRatio fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText primary={t("tracking.overlays")} />
                                    </MenuItem>
                                )}
                            </MenuList>
                        </Paper>
                    </div>
                </div>
            )}
        </>
    )
}
