import { CheckBoxOutlineBlank } from "@mui/icons-material"
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    Stack,
    Typography,
} from "@mui/material"
import { useSnackbar } from "notistack"
import { Fragment, ReactElement, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { ArchiveExportTask, CreateArchiveExportTaskRequest } from "../../api/ArchiveExportTask"
import { asCameraSubject, Operation } from "../../api/Authz"
import { Site, Unit } from "../../api/Customer"
import useAuthorizer from "../../auth/AuthorizerProvider"
import { http, snackbarSuccess } from "../../backend/request"
import { request } from "../../config/headers"
import { AddDuration, FloorToScale, Hour, Minute, Second, TimeDifference, ToDuration } from "../../config/time"
import { endpointURL } from "../../config/urls"
import { Node, Selected, useTreeSet } from "../../hooks/treeset"
import { EventFilter } from "../../services/EventFilter"
import { TimeChoice } from "../common/TimeChoice"

export interface ArchiveExportDialogProps {
    initialTime: Date
    initialFilter: EventFilter
    site: Site
    units: Unit[]
    parent?: Element
    onClose: () => void
}

const defaultRange = 5 * Minute
const maxDuration = 24 * Hour
const maxCameras = 50

interface camera {
    UnitID: number
    CameraID: number
}

export function ArchiveExportDialog(props: ArchiveExportDialogProps) {
    const { initialTime, initialFilter, site, units, parent, onClose } = props
    const { t } = useTranslation()
    const snackbar = useSnackbar()

    const [error, setError] = useState("")

    const [start, setStart] = useState(FloorToScale(initialTime, Second))
    const [end, setEnd] = useState(FloorToScale(AddDuration(initialTime, defaultRange), Second))

    const { allowOperation } = useAuthorizer()

    const tree = useMemo(() => {
        const c = units.map((u) => {
            const c = (u.UnitConfig.Cameras || []).map((c) => ({
                id: `${u.ID}/${c.ID}`,
                disable: !allowOperation(Operation.EXPORT_CAMERA_ARCHIVE, asCameraSubject(u, c.ID)),
                value: {
                    UnitID: u.ID,
                    CameraID: c.ID,
                },
            }))
            const disable = !c.some((e) => e.disable === false)
            return {
                id: u.DisplayName,
                disable: disable,
                children: c,
            }
        })

        const disable = !c.some((e) => e.disable === false)
        return {
            id: t("dialog.archive_export.all_cameras"),
            disable: disable,
            children: c,
        }
    }, [units, allowOperation, t])
    const { ids, values, nodes, flip } = useTreeSet(tree, initialFilter.ids)

    useEffect(() => {
        if (AddDuration(end, -defaultRange) >= new Date()) {
            setError(t("dialog.archive_export.future_export_error"))
            return
        }
        if (start >= end) {
            setError(t("dialog.archive_export.start_after_end_error"))
            return
        }
        if (TimeDifference(start, end) > maxDuration) {
            setError(t("dialog.archive_export.max_duration_error"))
            return
        }
        if (ids.size === 0) {
            setError(t("dialog.archive_export.no_cameras_error"))
            return
        }
        if (ids.size > maxCameras) {
            setError(t("dialog.archive_export.max_cameras_error", { max: maxCameras }))
            return
        }
        setError("")
    }, [ids, start, end, t])

    const onAccept = () => {
        const units = new Map<number, number[]>()
        values.forEach((c) => {
            var list = units.get(c.UnitID) || []
            list.push(c.CameraID)
            units.set(c.UnitID, list)
        })
        sendExportRequest({
            start: start,
            end: end,
            sites: [
                {
                    id: site.ID,
                    units: Array.from(units.entries()).map(([k, v]) => {
                        return {
                            id: k,
                            cameras: v.map((id) => {
                                return { id }
                            }),
                        }
                    }),
                },
            ],
        })
    }

    const sendExportRequest = (r: CreateArchiveExportTaskRequest) => {
        http<ArchiveExportTask>("Creating archive export task", endpointURL("queues/archive_export/tasks"), snackbar, {
            method: "POST",
            headers: request.headers,
            body: JSON.stringify(r),
        })
            .then((task) => {
                console.log("Successfuly created task: ", task)
                snackbarSuccess(t("dialog.archive_export.success", { email: task.payload.email }), snackbar)
            })
            .catch((e) => console.log(e))
        onClose()
    }

    const renderTree = (node: Node<camera>, indent: number): ReactElement => (
        <Fragment key={node.id}>
            <FormControlLabel
                label={node.id.includes("/") ? t("camera.nth_camera", { id: node.id.split("/")[1] }) : node.id}
                style={{ paddingLeft: `${28 * indent}px` }}
                control={
                    <Checkbox
                        checked={nodes.get(node.id) === Selected.YES}
                        indeterminate={nodes.get(node.id) === Selected.PARTIAL}
                        onChange={() => flip(node.id)}
                        size="small"
                        disabled={node.disable}
                        indeterminateIcon={<CheckBoxOutlineBlank />}
                        style={{ padding: "4px" }}
                    />
                }
            />
            {node.children?.map((ch) => renderTree(ch, indent + 1))}
        </Fragment>
    )

    return (
        <Dialog
            onAbort={onClose}
            onClose={onClose}
            onSubmit={onClose}
            container={parent}
            aria-labelledby="archive-export-dialog-title"
            maxWidth="xs"
            open
        >
            <DialogTitle id="archive-export-dialog-title">
                {t("dialog.archive_export.title")}
                <Divider variant="fullWidth" flexItem />
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Stack spacing={1}>
                            <Stack direction="row" spacing={1}>
                                <Typography pt="0.5em" fontWeight="bold">
                                    {t("dialog.archive_export.range")}
                                </Typography>
                                <Typography pt="0.5em">
                                    ({t("dialog.archive_export.duration")} {ToDuration(TimeDifference(start, end))})
                                </Typography>
                            </Stack>
                            <TimeChoice
                                value={start}
                                label={"dialog.archive_export.fragment_start"}
                                onChange={setStart}
                                parent={parent}
                            />
                            <TimeChoice
                                value={end}
                                label={"dialog.archive_export.fragment_end"}
                                onChange={setEnd}
                                parent={parent}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack>
                            <Typography pt="0.5em" fontWeight="bold">
                                {t("dialog.archive_export.cameras")}
                            </Typography>
                            {renderTree(tree, 1)}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} minHeight="32px">
                        {error && (
                            <Typography fontSize="small" variant="body2" color="error">
                                {error}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onAccept} variant="contained" disabled={!!error}>
                    {t("action.ok")}
                </Button>
                <Button onClick={onClose} variant="outlined">
                    {t("action.cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
