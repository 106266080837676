import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { Customer } from "../api/Customer"
import { WhoAmI } from "../api/WhoAmI"
import { minPageWidth } from "../config/sizing"
import { PageError } from "./PageError"
import { Breadcrumb, UnitAppBar } from "./UnitAppBar"

export interface CustomerPageProps {
    whoAmI: WhoAmI
    customer?: Customer
    page?: Breadcrumb
    customers: Customer[]
    allowed: boolean
    showArchived: boolean
    setShowArchived: (value: boolean) => void
    render: (customer: Customer) => React.ReactNode
}

export const CustomerPage = (props: CustomerPageProps) => {
    const { whoAmI, customer, page, customers, allowed, showArchived, setShowArchived, render } = props

    const { customerID } = useParams()
    const { t } = useTranslation()

    const renderBody = () => {
        if (!customer) {
            return <PageError message={t("message.customer_not_found", { id: customerID })} />
        }
        if (!allowed) {
            return <PageError message={t("message.forbidden_page")} />
        }
        return render(customer)
    }

    return (
        <Box sx={{ display: "flex", width: "100%", height: "100%", minWidth: minPageWidth, flexDirection: "column" }}>
            <UnitAppBar
                whoAmI={whoAmI}
                customer={customer}
                page={page}
                customers={customers}
                showArchived={showArchived}
                setShowArchived={setShowArchived}
            />
            {renderBody()}
        </Box>
    )
}
