import i18n from "i18next"
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector"
import I18NextHttpBackend from "i18next-http-backend"
import { initReactI18next } from "react-i18next"
import { staticURL } from "./config/urls"

i18n.use(I18NextHttpBackend)
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        debug: true,
        returnNull: false,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        backend: {
            loadPath: staticURL("locales/{{lng}}/{{ns}}.json"),
        },
    })

export default i18n
