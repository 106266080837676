import { Role, RoleType } from "../../api/Authz"
import { Roles } from "../../api/portal/hooks/Roles"
import { BaseRoleChip } from "./BaseRoleChip"
import { CommonRoleChip } from "./CommonRoleChip"

export interface RoleChipProps {
    role: Role
    roles: Roles
}

export const RoleChip = (props: RoleChipProps) => {
    const { role, roles } = props

    return role.type === RoleType.BASE_ROLE ? (
        <BaseRoleChip baseRole={role} roles={roles} />
    ) : (
        <CommonRoleChip commonRole={role} roles={roles} />
    )
}
