import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { request } from "../../config/headers"
import { staticURL } from "../../config/urls"
import { Version } from "../Version"

export const versionApi = createApi({
    reducerPath: "versionApi",
    baseQuery: fetchBaseQuery({ ...request, baseUrl: staticURL("") }),
    tagTypes: ["Version"],
    endpoints: (builder) => ({
        getVersion: builder.query<Version, void>({
            query: () => `version.json`,
            providesTags: [{ type: "Version" }],
        }),
    }),
})

export const { useGetVersionQuery } = versionApi
