import { Autorenew, CreditCard, DeleteForever, Edit } from "@mui/icons-material"
import { IconButton, Stack, Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Operation } from "../api/Authz"
import { User } from "../api/User"
import { useCustomerPermission } from "../auth/AuthorizerProvider"

export interface UserActionsProps {
    actor: User
    user: User
    customerID: number
    onEdit: () => void
    onDelete: () => void
    onResetPassword: () => void
    onEditIdentifiers: () => void
}

export function UserActions(props: UserActionsProps) {
    const { actor, user, customerID, onEdit, onDelete, onResetPassword, onEditIdentifiers } = props

    const { t } = useTranslation()

    const allowEdit = useCustomerPermission(Operation.UPDATE_CUSTOMER_USER, user.CustomerID) || actor.ID === user.ID
    const allowDelete = useCustomerPermission(Operation.DELETE_CUSTOMER_USER, user.CustomerID) && actor.ID !== user.ID
    const allowEditIdentifiers = useCustomerPermission(Operation.LIST_CUSTOMER_IDENTIFIERS, customerID)

    return (
        <Stack direction="row">
            <Tooltip title={t("action.edit_user_tooltip")} disableInteractive>
                <span>
                    <IconButton
                        size="small"
                        color="primary"
                        aria-label="edit user"
                        component="span"
                        disabled={!allowEdit}
                        onClick={onEdit}
                    >
                        <Edit />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title={t("action.manage_identifiers_tooltip")} disableInteractive>
                <span>
                    <IconButton
                        size="small"
                        color="primary"
                        aria-label="edit user identifiers"
                        component="span"
                        disabled={!allowEditIdentifiers}
                        onClick={onEditIdentifiers}
                    >
                        <CreditCard />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title={t("action.reset_password_tooltip")} disableInteractive>
                <span>
                    <IconButton
                        size="small"
                        color="primary"
                        aria-label="reset password"
                        component="span"
                        disabled={!allowEdit}
                        onClick={onResetPassword}
                    >
                        <Autorenew />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title={t("action.delete_user_tooltip")} disableInteractive>
                <span>
                    <IconButton
                        size="small"
                        color="secondary"
                        aria-label="delete user account"
                        component="span"
                        disabled={!allowDelete}
                        onClick={onDelete}
                    >
                        <DeleteForever />
                    </IconButton>
                </span>
            </Tooltip>
        </Stack>
    )
}
