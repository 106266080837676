import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { Unit } from "../../../api/Customer"
import { Camera } from "../api/Camera"
import { CameraActions } from "./CameraActions"

export interface CamerasDialogProps {
    open: boolean
    admin: boolean
    unit: Unit
    cameras: Camera[]
    onClose: () => void
    onCalibrate: (camera: Camera) => void
    onTarget: (camera: Camera) => void
}

export function CamerasDialog(props: CamerasDialogProps) {
    const { open, admin, unit, cameras, onClose, onCalibrate, onTarget } = props

    const { t } = useTranslation()

    const cameraRow = (camera: Camera) => {
        return (
            <TableRow key={camera.ID}>
                <TableCell component="th" scope="row">
                    {camera.Name}
                </TableCell>
                <TableCell align="right">{camera.DeviceID}</TableCell>
                <TableCell align="right">{camera.URL}</TableCell>
                <TableCell align="right">{camera.Height}</TableCell>
                <TableCell align="right">{camera.AutoTargetDeviceID ? camera.AutoTargetDeviceID : "-"}</TableCell>
                <TableCell align="right">
                    <CameraActions
                        admin={admin}
                        unit={unit}
                        cameraID={camera.ID}
                        onTarget={() => onTarget(camera)}
                        onCalibrate={() => onCalibrate(camera)}
                    />
                </TableCell>
            </TableRow>
        )
    }

    return (
        <Dialog onClose={onClose} aria-labelledby="cameras-dialog-title" maxWidth="md" fullWidth open={open}>
            <DialogTitle id="cameras-dialog-title">{t("tracking.cameras")}</DialogTitle>
            <TableContainer component="div" style={{ overflow: "auto", paddingBottom: 5, maxHeight: "75%" }}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("field.name")}</TableCell>
                            <TableCell align="right">{t("field.device_id")}</TableCell>
                            <TableCell align="right">{t("field.url")}</TableCell>
                            <TableCell align="right">{t("field.height")}</TableCell>
                            <TableCell align="right">{t("field.autotarget_id")}</TableCell>
                            <TableCell align="right">{t("field.actions")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{cameras.map(cameraRow)}</TableBody>
                </Table>
            </TableContainer>
            <DialogActions>
                <Button onClick={onClose} variant="contained" color="primary">
                    {t("action.close")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
