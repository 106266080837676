import { TableCell, TableRow, Typography, useTheme } from "@mui/material"

export interface TextRowProps {
    text: string
}

export const TextRow = (props: TextRowProps) => {
    const { text } = props

    const theme = useTheme()

    return (
        <TableRow>
            <TableCell colSpan={99999}>
                <Typography variant="body2" fontStyle="italic" color={theme.palette.text.secondary}>
                    {text}
                </Typography>
            </TableCell>
        </TableRow>
    )
}
