import { createElementObject, createLayerComponent, extendContext, updateMediaOverlay } from "@react-leaflet/core"
import { CrossOrigin, DistortableImageOverlay as LeafletDistortableImageOverlay } from "leaflet"
import { ReactNode } from "react"

// Note that the import order is important here due to linking against a JavaScript dependency,
// which sadly does not have a React TypeScript counterpart.
// 1.
import "leaflet-toolbar"
// 2.
import "leaflet-distortableimage"

export interface DistortableImageOverlayProps {
    children?: ReactNode
    url: string
    mode: string
    actions: any[]
    corners: L.LatLng[]
    selected: boolean
    editable: boolean
    suppressToolbar: boolean
    crossOrigin?: CrossOrigin | boolean | undefined
    onUpdate: (corners: L.LatLng[]) => void
}

export const DistortableImageOverlay = createLayerComponent<
    LeafletDistortableImageOverlay,
    DistortableImageOverlayProps
>(
    function createImageOveraly({ url, ...options }, ctx) {
        const overlay = new LeafletDistortableImageOverlay(url, options)
        overlay.addEventListener("update", () => {
            options.onUpdate(overlay.getCorners())
        })
        return createElementObject(overlay, extendContext(ctx, { overlayContainer: overlay }))
    },
    function updateImageOverlay(overlay, props, prevProps) {
        const bounds = overlay.getBounds()
        updateMediaOverlay(overlay, { ...props, bounds }, { ...prevProps, bounds })
        if (props.url !== prevProps.url) {
            overlay.setUrl(props.url)
        }
        if (props.editable !== prevProps.editable) {
            if (props.editable) {
                overlay.editing.enable()
            } else {
                overlay.editing.disable()
            }
        }
        if (props.selected !== prevProps.selected) {
            if (props.selected) {
                overlay.select()
            } else {
                overlay.deselect()
            }
        }
        if (props.corners !== prevProps.corners) {
            overlay.setCorners(props.corners)
        }
    }
)
