import { MobiledataOff, TaskAlt } from "@mui/icons-material"
import { Stack, Tooltip, Typography, useTheme } from "@mui/material"
import { Box } from "@mui/system"
import { useSnackbar } from "notistack"
import { useCallback, useEffect, useMemo, useState } from "react"
import { openHabEndpointURL } from "../config/urls"
import { emphasized } from "../theme/Theme"
import { Unit } from "./Customer"
import { Event, Payload } from "./OpenHAB"

export interface OpenHABStreamProps {
    unit: Unit
    hidden?: boolean
    itemFilter: (name: string) => boolean
    onMessage: (name: string, payload: Payload) => void
    onConnected: () => void
}

export const OpenHABStream = (props: OpenHABStreamProps) => {
    const { unit, hidden, itemFilter, onMessage, onConnected } = props

    const [connected, setConnected] = useState(false)
    const [generation, setGeneration] = useState(0)

    const theme = useTheme()
    const snackbar = useSnackbar()

    const topicRE = useMemo(() => new RegExp("^openhab/items/([^/]+)/(.*/)?statechanged$"), [])

    const scheduleReconnection = useCallback((lastAttempt: Date) => {
        if (new Date().getTime() - lastAttempt.getTime() < 5000) {
            setTimeout(() => setGeneration((g) => g + 1), 5000)
        } else {
            setGeneration((g) => g + 1)
        }
    }, [])

    useEffect(() => {
        const lastAttempt = new Date()
        const source = new EventSource(openHabEndpointURL(unit, `events?topics=openhab/items/*/statechanged`))
        source.onmessage = (e) => {
            const event = JSON.parse(e.data) as Event
            const payload = JSON.parse(event.payload) as Payload
            const match = event.topic.match(topicRE)
            if (!match || match.length < 2 || !payload) {
                console.warn("could not parse sse event data", e)
                return
            }
            const itemName = match[1]
            if (!itemFilter(itemName)) {
                return
            }
            onMessage(itemName, payload)
        }
        source.onerror = (_) => {
            source.close()
            setConnected(false)
            scheduleReconnection(lastAttempt)
        }
        setConnected(true)
        onConnected()

        return () => {
            if (source.readyState !== source.CLOSED) {
                source.close()
            }
        }
    }, [snackbar, unit, scheduleReconnection, topicRE, onMessage, onConnected, itemFilter, generation])

    return hidden ? null : (
        <Stack direction="row" sx={{ width: "100%" }}>
            <Box sx={{ flexGrow: 1 }} />
            <Tooltip title={connected ? "Receiving state updates." : "Not receiving state updates."} disableInteractive>
                <Stack direction="row" sx={{ alignItems: "center" }} spacing={0.2}>
                    <Typography
                        variant="caption"
                        color={connected ? emphasized(0.2, theme) : theme.palette.warning.main}
                    >
                        {connected ? "online" : "offline"}
                    </Typography>
                    {connected ? (
                        <TaskAlt fontSize="small" htmlColor={emphasized(0.2, theme)} />
                    ) : (
                        <MobiledataOff fontSize="small" htmlColor={theme.palette.warning.main} />
                    )}
                </Stack>
            </Tooltip>
        </Stack>
    )
}
