import { BaseSyntheticEvent } from "react"

export const minPageWidth = "350px"

const ignore = (e: BaseSyntheticEvent<any>) => e.stopPropagation()

export const wideIncativeTooltip = {
    tooltip: {
        sx: { maxWidth: 800 },
        onClick: ignore,
        onMouseDown: ignore,
    }
}
