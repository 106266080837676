import { useCallback, useEffect, useMemo, useReducer } from "react";
import { hookTimeout } from "../config/time";

type State = {
    cooltime: number | null
}

type Action = { type: "Start"; period: number } | { type: "End" }

function reducer(state: State, action: Action) {
    switch (action.type) {
        case "Start":
            return {
                ...state,
                cooltime: Date.now() + action.period,
            }
        case "End":
            return {
                ...state,
                cooltime: null,
            }
    }
}

export function useCooldown(period: number) {
    const [state, dispatch] = useReducer(reducer, { cooltime: null })

    const cancel = useCallback(() => dispatch({ type: "End" }), [])
    const trigger = useCallback(() => dispatch({ type: "Start", period }), [period])

    useEffect(() => {
        if (state.cooltime === null) {
            return
        }
        return hookTimeout(cancel, period)
    }, [cancel, period, state.cooltime])

    const cooling = useMemo(() => state.cooltime !== null, [state.cooltime])

    return { cooling, trigger, cancel }
}
