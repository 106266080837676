import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import { useTranslation } from "react-i18next"

export interface PickCommandDialogProps {
    open: boolean
    commands: string[]
    onClose: () => void
    onPicked: (command: string) => void
    parent?: Element
}

export function PickCommandDialog(props: PickCommandDialogProps) {
    const { open, commands, onClose, onPicked, parent } = props

    const { t } = useTranslation()

    const displayName = (command: string) => {
        if (command.startsWith("tt:")) {
            return command.substring(3).replace("|", ": ")
        }
        return command
    }

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="pick-command-dialog-title"
            maxWidth="lg"
            fullWidth
            open={open}
            container={parent}
        >
            <DialogTitle id="pick-command-dialog-title">{t("dialog.pick_command.title")}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    {commands.map((command) => (
                        <Grid key={command} item xs={12} sm={6} md={3} lg={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={() => onPicked(command)}
                                fullWidth
                            >
                                {displayName(command)}
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained" color="primary">
                    {t("action.cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
