import { CircularProgress, Grid, Stack, Switch, Tooltip, Typography, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Operation } from "../../api/Authz"
import { Unit } from "../../api/Customer"
import { useAlarmPermission } from "../../auth/AuthorizerProvider"

export interface OutputInfoProps {
    id: number
    displayName?: string
    type?: string
    unit: Unit
    alarmID: number
    value?: boolean
    flipSwitch: (on: boolean) => void
}

export const OutputInfo = (props: OutputInfoProps) => {
    const { id, displayName, type, unit, alarmID, value, flipSwitch } = props
    const theme = useTheme()
    const { t } = useTranslation()

    const allowOutputOperation = useAlarmPermission(Operation.SWITCH_OUTPUT, unit, alarmID)

    const label = displayName || t("alarm.output_display_name", { id: id })
    const tooltip = `${t("alarm.output_display_name", { id: id })}${!displayName ? "" : ": " + displayName}.`

    const OutputSwitch = (value: boolean) => {
        if (type === "mono") {
            return (
                <Switch
                    checkedIcon={
                        <CircularProgress
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "50%",
                                color: theme.palette.grey[100],
                                backgroundColor: theme.palette.primary.light,
                                boxShadow: 5,
                            }}
                            size={20}
                            thickness={6}
                        />
                    }
                    disabled={!allowOutputOperation}
                    checked={value}
                    onClick={() => flipSwitch(value)}
                />
            )
        }

        return <Switch disabled={!allowOutputOperation} checked={value} onClick={() => flipSwitch(value)} />
    }

    return (
        <Grid item xs={12} sm={6} md={4} lg={3}>
            <Stack
                direction="row"
                px={1}
                height="100%"
                sx={{ alignItems: "center", backgroundColor: theme.palette.background.paper }}
            >
                <Stack
                    direction="row"
                    sx={{
                        alignItems: "center",
                        flexGrow: 1,
                        overflow: "hidden",
                    }}
                >
                    {value !== undefined && (
                        <Tooltip
                            title={value ? t("action.switch_off_tooltip") : t("action.switch_on_tooltip")}
                            disableInteractive
                        >
                            <span>{OutputSwitch(value)}</span>
                        </Tooltip>
                    )}
                    <Tooltip title={tooltip} disableInteractive>
                        <Typography
                            sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {label}
                        </Typography>
                    </Tooltip>
                </Stack>
            </Stack>
        </Grid>
    )
}
