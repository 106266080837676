import { TFunction } from "i18next"

type checkCallback = (s: string | undefined, t: TFunction) => string
const validate = (check: checkCallback) => {
    return (text: string | undefined, t: TFunction, errorSetter: (s: string) => void) => {
        const err = check(text, t)
        errorSetter(err)
        return !err
    }
}
const and =
    (...checks: checkCallback[]) =>
    (s: string | undefined, t: TFunction) =>
        checks.map((check) => check(s, t)).find((err) => !!err) || ""

const check = (b: boolean, message: string, t: TFunction) => (b ? "" : t(message))
const checkNonEmpty = (s: string | undefined, t: TFunction) => check(!!s, "message.required_field", t)
const checkMinLen = (min: number) => (s: string | undefined, t: TFunction) =>
    check(!!s && s.length >= min, "message.too_short", t)
const checkLowerCase = (s: string | undefined, t: TFunction) =>
    check(s?.toLowerCase() === s, "message.require_lower_case", t)
const checkRE = (re: RegExp, error: string) => (s: string | undefined, t: TFunction) =>
    check(!!s && re.test(s), error, t)

const emailRE = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const usernameRE = /^[a-zA-Z0-9_.-]+$/
const minUsernameLength = 5
const minEmailLength = 5
const minPasswordLength = 8

export const validateNonEmpty = validate(checkNonEmpty)
export const validateEmail = validate(
    and(checkNonEmpty, checkMinLen(minEmailLength), checkRE(emailRE, "message.required_email"), checkLowerCase)
)
export const validateUsername = validate(
    and(checkNonEmpty, checkMinLen(minUsernameLength), checkRE(usernameRE, "message.required_username"))
)
export const validatePassword = validate(and(checkNonEmpty, checkMinLen(minPasswordLength)))
