import { Popup, useMap } from "react-leaflet"
import { Unit } from "../../../api/Customer"
import useConfig from "../../../config/Provider"
import { Camera } from "../api/Camera"
import { Device } from "../api/Device"
import { GPS, toLeafletPosition } from "../api/GPS"
import { DeviceInfo } from "../components/DeviceInfo"

export interface DevicePopupProps {
    admin: boolean
    moveCameras: boolean
    unit: Unit
    device: Device
    position?: GPS
    cameras: Camera[]
    onPickDevicePosition: () => void
    onClearDevicePosition: () => void
    onTargetDevice: () => void
    onEditDevice: () => void
    onDeleteDevice: () => void
    onTargetCamera: (camera: Camera) => void
    onCalibrateCamera: (camera: Camera) => void
}

export function DevicePopup(props: DevicePopupProps) {
    const {
        admin,
        moveCameras,
        unit,
        device,
        position,
        cameras,
        onPickDevicePosition,
        onClearDevicePosition,
        onTargetDevice,
        onEditDevice,
        onDeleteDevice,
        onTargetCamera,
        onCalibrateCamera,
    } = props

    const map = useMap()
    const { mode } = useConfig()

    const closePopups = () => {
        map.closePopup()
    }

    const onFocusDevice = () => {
        if (position) {
            map.flyTo(toLeafletPosition(position))
        }
    }

    return (
        <Popup className={`map-popup-${mode}`} minWidth={400}>
            <DeviceInfo
                admin={admin}
                moveCameras={moveCameras}
                unit={unit}
                device={device}
                position={position}
                cameras={cameras}
                onClearDevicePosition={() => {
                    onClearDevicePosition()
                    closePopups()
                }}
                onPickDevicePosition={() => {
                    onPickDevicePosition()
                    closePopups()
                }}
                onTargetDevice={() => {
                    onTargetDevice()
                    closePopups()
                }}
                onFocusDevice={() => {
                    onFocusDevice()
                    closePopups()
                }}
                onEditDevice={() => {
                    onEditDevice()
                    closePopups()
                }}
                onDeleteDevice={() => {
                    onDeleteDevice()
                    closePopups()
                }}
                onCalibrate={(c: Camera) => {
                    onCalibrateCamera(c)
                    closePopups()
                }}
                onTarget={(c: Camera) => {
                    onTargetCamera(c)
                    closePopups()
                }}
            />
        </Popup>
    )
}
