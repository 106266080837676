import { GpsFixed, LinkedCamera } from "@mui/icons-material"
import {
    Box,
    Card,
    CardContent,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { Unit } from "../../../api/Customer"
import { Camera } from "../api/Camera"
import { Device } from "../api/Device"
import { GPS, formatGPS } from "../api/GPS"
import { DeviceActions } from "../components/DeviceActions"
import { CameraActions } from "./CameraActions"

export interface DeviceInfoProps {
    admin: boolean
    moveCameras: boolean
    unit: Unit
    device: Device
    position?: GPS
    cameras: Camera[]
    onPickDevicePosition: () => void
    onClearDevicePosition: () => void
    onTargetDevice: () => void
    onFocusDevice: () => void
    onEditDevice: () => void
    onDeleteDevice: () => void
    onCalibrate: (c: Camera) => void
    onTarget: (c: Camera) => void
}

export function DeviceInfo(props: DeviceInfoProps) {
    const {
        admin,
        moveCameras,
        unit,
        device,
        position,
        cameras,
        onPickDevicePosition,
        onClearDevicePosition,
        onTargetDevice,
        onFocusDevice,
        onEditDevice,
        onDeleteDevice,
        onCalibrate,
        onTarget,
    } = props

    const { t } = useTranslation()

    const renderField = (k: string, v: string) => (
        <TableRow>
            <TableCell>{k}</TableCell>
            <TableCell>{v}</TableCell>
        </TableRow>
    )

    const renderCamera = (camera: Camera, idx: number, cameras: Camera[]) => (
        <TableRow>
            {idx === 0 && (
                <TableCell rowSpan={cameras.length}>
                    <Typography component="span" variant="body2" flexGrow={1}>
                        {t("tracking.cameras")}
                    </Typography>
                </TableCell>
            )}
            <TableCell>
                <Stack direction="row" spacing={1}>
                    <LinkedCamera fontSize="small" />
                    <Typography component="span" variant="body2" flexGrow={1}>
                        {camera.Name}
                    </Typography>
                    <CameraActions
                        admin={admin}
                        unit={unit}
                        cameraID={camera.ID}
                        onCalibrate={() => onCalibrate(camera)}
                        onTarget={() => onTarget(camera)}
                    />
                </Stack>
            </TableCell>
        </TableRow>
    )

    return (
        <Card elevation={0}>
            <CardContent sx={{ padding: "0px", ":last-child": { paddingBottom: "8px" } }}>
                <Stack>
                    <TableContainer component="div" style={{ overflow: "auto", paddingBottom: 5, maxHeight: "75%" }}>
                        <Table size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <Stack direction="row" spacing={1}>
                                            <GpsFixed fontSize="small" />
                                            <Typography component="span" flexGrow={1}>
                                                {device.Name}
                                            </Typography>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {renderField(t("field.id"), device.ID.toString())}
                                {renderField(t("field.imei"), device.IMEI)}
                                {renderField(t("field.gps"), position ? formatGPS(position) : t("field.unknown"))}
                                {renderField(
                                    t("field.updated"),
                                    position ? new Date(position.CreatedAt).toLocaleString() : t("field.unknown")
                                )}
                                {cameras.map(renderCamera)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box padding={1}>
                        <DeviceActions
                            admin={admin}
                            moveCameras={moveCameras}
                            hasPosition={!!position}
                            onPickDevicePosition={onPickDevicePosition}
                            onClearDevicePosition={onClearDevicePosition}
                            onFocusDevice={onFocusDevice}
                            onTargetDevice={onTargetDevice}
                            onEditDevice={onEditDevice}
                            onDeleteDevice={onDeleteDevice}
                        />
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    )
}
