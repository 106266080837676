import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from "@mui/material"
import { useConfirm } from "material-ui-confirm"
import { useSnackbar } from "notistack"
import React, { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Customer } from "../api/Customer"
import { AdminRole, CustomerRole, isGlobalAdmin } from "../api/Roles"
import { User } from "../api/User"
import { http, snackbarSuccess } from "../backend/request"
import { request } from "../config/headers"
import { ToShortLocalDateTime } from "../config/time"
import { endpointURL } from "../config/urls"
import { formatUserLong } from "../widgets/format"
import { PickExternalUserDialog } from "./PickExternalUserDialog"
import { UserActions } from "./UserActions"
import { UserDialog } from "./UserDialog"
import { UserIdentifierManagementDialog } from "./UserIdentifierManagementDialog"

export interface UserManagementDialogProps {
    customerID: number
    actor: User
    customers: Customer[]
    onClose: () => void
}

export function UserManagementDialog(props: UserManagementDialogProps) {
    const { customerID, actor, customers, onClose } = props
    const [loaded, setLoaded] = React.useState(false)
    const [error, setError] = React.useState<any>()
    const [users, setUsers] = React.useState<User[]>([])
    const [customerRoles, setCustomerRoles] = React.useState<CustomerRole[]>([])
    const [technicianDialogOpen, setTechnicianDialogOpen] = React.useState(false)
    const [userDialogOpen, setUserDialogOpen] = React.useState(false)
    const [editingUser, setEditingUser] = React.useState<User | undefined>(undefined)
    const [editingUsersIdentifiers, setEditingUsersIdentifiers] = React.useState<User | undefined>(undefined)

    const confirm = useConfirm()
    const snackbar = useSnackbar()
    const theme = useTheme()
    const { t } = useTranslation()

    const reload = useCallback(() => {
        if (customerID) {
            setLoaded(false)
            setError(null)
            Promise.all([
                http<User[]>(
                    "Loading customer users",
                    endpointURL(`customers/${customerID}/users`),
                    snackbar,
                    request
                ).then((result) => setUsers(result), setError),
                http<CustomerRole[]>(
                    "Loading customer roles",
                    endpointURL(`customers/${customerID}/roles`),
                    snackbar,
                    request
                ).then((result) => setCustomerRoles(result), setError),
            ]).then(() => setLoaded(true))
        }
    }, [customerID, snackbar])

    useEffect(reload, [reload])

    const onEdit = (user: User) => {
        setEditingUser(user)
        setUserDialogOpen(true)
    }

    const onNew = () => {
        setEditingUser(undefined)
        setUserDialogOpen(true)
    }

    const onCreate = (user: User) => {
        http<User>(`Creating user '${user.Email}'`, endpointURL(`users`), snackbar, {
            method: "POST",
            headers: request.headers,
            body: JSON.stringify(user),
        })
            .then((result) => setUsers(users.concat(result)))
            .catch((e) => console.log(e))
            .finally(() => setUserDialogOpen(false))
    }

    const onUpdate = (user: User) => {
        http<User>(`Updating user '${user.Email}'`, endpointURL(`users/${user.ID}`), snackbar, {
            method: "PUT",
            headers: request.headers,
            body: JSON.stringify(user),
        })
            .then((result) => setUsers(users.map((u) => (u.ID === user.ID ? result : u))))
            .catch((e) => console.log(e))
            .finally(() => setUserDialogOpen(false))
    }

    const onPicked = (user: User, expiration: Date) => {
        confirm({
            title: t("confirm.generic"),
            description: t("confirm.add_technician", {
                user: formatUserLong(t, user),
                expiration: ToShortLocalDateTime(expiration),
            }),
            confirmationText: t("action.add"),
            cancellationText: t("action.cancel"),
            confirmationButtonProps: {
                color: "secondary",
            },
        })
            .then(() => {
                http<User>(
                    `Adding user role '${user.Email}'`,
                    endpointURL(`users/${user.ID}/customer/${customerID}/roles`),
                    snackbar,
                    {
                        method: "PUT",
                        headers: request.headers,
                        body: JSON.stringify({
                            userId: user.ID,
                            customerId: customerID,
                            role: "ADMIN",
                            expiration: expiration,
                        }),
                    }
                )
                    .then(reload)
                    .catch((e) => console.log(e))
                    .finally(() => setTechnicianDialogOpen(false))
            })
            .catch(() => {})
    }

    const onDelete = (user: User) => {
        confirm({
            title: t("confirm.generic"),
            description: t("confirm.delete_user_account", { user: formatUserLong(t, user) }),
            confirmationText: t("action.delete"),
            cancellationText: t("action.cancel"),
            confirmationButtonProps: {
                color: "secondary",
            },
        })
            .then(() => {
                http<any>("Deleting user account", endpointURL(`users/${user.ID}`), snackbar, {
                    method: "DELETE",
                    headers: request.headers,
                })
                    .then(() => setUsers(users.filter((u) => u.ID !== user.ID)))
                    .catch((e) => console.log(e))
            })
            .catch(() => {})
    }

    const onResetPassword = (user: User) => {
        confirm({
            title: t("confirm.generic"),
            description: t("confirm.reset_password", { user: formatUserLong(t, user) }),
            confirmationText: t("action.reset_password"),
            cancellationText: t("action.cancel"),
            confirmationButtonProps: {
                color: "secondary",
            },
        })
            .then(() => {
                http<any>("Reseting user's password", endpointURL(`users/${user.ID}/password`), snackbar, {
                    method: "DELETE",
                    headers: request.headers,
                })
                    .then(() =>
                        snackbarSuccess(`Password was reset and sent to user's email: ${user.Email}.`, snackbar)
                    )
                    .catch((e) => console.log(e))
            })
            .catch(() => {})
    }

    const userRow = (user: User) => {
        const role = customerRoles.find((r) => r.UserID === user.ID)
        return (
            <TableRow key={user.Username}>
                <TableCell>
                    {user.Username}
                    {user.CustomerID !== customerID && (
                        <Typography fontSize="small" color={theme.palette.text.secondary}>
                            ({t("tag.external")})
                        </Typography>
                    )}
                </TableCell>
                <TableCell>{user.Surname}</TableCell>
                <TableCell>{user.Name}</TableCell>
                <TableCell>{user.Email}</TableCell>
                <TableCell>
                    {role?.Expiration ? ToShortLocalDateTime(role?.Expiration) : <em>{t("message.never")}</em>}
                </TableCell>
                <TableCell>
                    <Checkbox checked={role?.Role === AdminRole} disabled />
                </TableCell>
                <TableCell align="right">
                    <UserActions
                        user={user}
                        actor={actor}
                        customerID={customerID}
                        onEdit={() => onEdit(user)}
                        onDelete={() => onDelete(user)}
                        onResetPassword={() => onResetPassword(user)}
                        onEditIdentifiers={() => setEditingUsersIdentifiers(user)}
                    />
                </TableCell>
            </TableRow>
        )
    }

    return (
        <Dialog onClose={onClose} aria-labelledby="user-management-dialog-title" maxWidth="lg" open={!!customerID}>
            <DialogTitle id="user-management-dialog-title">{t("dialog.user_management.title")}</DialogTitle>
            {error && <Typography>{t("message.no_users")}</Typography>}
            {!loaded && !error && <Typography>{t("message.loading")}</Typography>}
            {loaded && !error && (
                <TableContainer component="div" style={{ overflow: "auto", paddingBottom: 5, maxHeight: "75%" }}>
                    <Table aria-label="users table" size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("field.username")}</TableCell>
                                <TableCell>{t("field.surname")}</TableCell>
                                <TableCell>{t("field.first_name")}</TableCell>
                                <TableCell>{t("field.email")}</TableCell>
                                <TableCell>{t("field.expiration")}</TableCell>
                                <TableCell>{t("field.admin")}</TableCell>
                                <TableCell>{t("field.actions")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{users.map(userRow)}</TableBody>
                    </Table>
                </TableContainer>
            )}
            <DialogActions>
                {isGlobalAdmin(actor.ID) && (
                    <Button onClick={() => setTechnicianDialogOpen(true)} variant="contained" color="primary">
                        {t("action.add_technician")}
                    </Button>
                )}
                <Button onClick={onNew} variant="contained" color="primary">
                    {t("action.new_user")}
                </Button>
                <Button onClick={onClose} variant="contained" color="primary">
                    {t("action.close")}
                </Button>
            </DialogActions>
            <PickExternalUserDialog
                open={technicianDialogOpen}
                customers={customers}
                targetCustomerID={customerID}
                onClose={() => setTechnicianDialogOpen(false)}
                onPicked={onPicked}
            />
            <UserDialog
                open={userDialogOpen}
                user={editingUser}
                customerID={customerID}
                onClose={() => setUserDialogOpen(false)}
                onUpdate={onUpdate}
                onCreate={onCreate}
            />
            <UserIdentifierManagementDialog
                customerID={customerID}
                user={editingUsersIdentifiers}
                onClose={() => setEditingUsersIdentifiers(undefined)}
            />
        </Dialog>
    )
}
