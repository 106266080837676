import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    TextField,
    useTheme,
} from "@mui/material"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Identifier, KeyCard, identifierTypeElement, identifierTypes } from "../api/Identifier"
import { validateNonEmpty } from "../widgets/validation"

export interface IdentifierDialogProps {
    open: boolean
    identifier?: Identifier
    customerID: number
    onClose: () => void
    onCreate: (identifier: Identifier) => void
    onUpdate: (identifier: Identifier) => void
}

export function IdentifierDialog(props: IdentifierDialogProps) {
    const { open, identifier, customerID, onClose, onCreate, onUpdate } = props
    const [id, setID] = React.useState(identifier?.ID)
    const [type, setType] = React.useState(identifier?.Type)
    const [value, setValue] = React.useState(identifier?.Value)
    const [description, setDescription] = React.useState(identifier?.Description)
    const [typeError, setTypeError] = React.useState("")
    const [valueError, setValueError] = React.useState("")
    const [descriptionError, setDescriptionError] = React.useState("")

    const { t } = useTranslation()
    const theme = useTheme()

    useEffect(() => {
        if (open) {
            clearError()
        }
    }, [open])

    useEffect(() => {
        setID(identifier?.ID)
        setType(identifier?.Type || KeyCard)
        setValue(identifier?.Value)
        setDescription(identifier?.Description)
    }, [identifier])

    const hasError = () => !checkType(type) || !checkValue(value) || !checkDescription(description)
    const clearError = () => {
        setTypeError("")
        setValueError("")
        setDescriptionError("")
    }

    const onSave = () => {
        if (hasError()) {
            return
        }
        // Do save it here.
        const toSave: Identifier = {
            ID: id || 0,
            Type: type || "",
            Value: value || "",
            Description: description || "",
            CustomerID: customerID,
        }
        if (!identifier) {
            onCreate(toSave)
        } else {
            onUpdate(toSave)
        }
    }

    const setTypeChecked = (s: string) => {
        setType(s)
        checkType(s)
    }
    const checkType = (s?: string) => validateNonEmpty(s, t, setTypeError)

    const setValueChecked = (s: string) => {
        setValue(s)
        checkValue(s)
    }
    const checkValue = (s?: string) => validateNonEmpty(s, t, setValueError)

    const setDescriptionChecked = (s: string) => {
        setDescription(s)
        checkDescription(s)
    }
    const checkDescription = (s?: string) => true

    return (
        <Dialog onClose={onClose} aria-labelledby="identifier-dialog-title" maxWidth="xs" open={open}>
            <DialogTitle id="identifier-dialog-title">
                {t(identifier ? "dialog.edit_identifier.title" : "dialog.new_identifier.title")}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={theme.spacing(2)}>
                    <Grid item xs={12}>
                        <TextField
                            id="Type"
                            label={t("field.type")}
                            defaultValue={identifier?.Type || KeyCard}
                            error={!!typeError}
                            helperText={typeError}
                            onChange={(event) => setTypeChecked(event.target.value)}
                            select
                            fullWidth={true}
                        >
                            {identifierTypes().map((i) => (
                                <MenuItem key={i} value={i}>
                                    {identifierTypeElement(i, t)}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="Value"
                            label={t("field.value")}
                            autoComplete="off"
                            defaultValue={identifier?.Value}
                            error={!!valueError}
                            helperText={valueError}
                            onChange={(event) => setValueChecked(event.target.value)}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="Description"
                            label={t("field.description")}
                            autoComplete="off"
                            defaultValue={identifier?.Description}
                            error={!!descriptionError}
                            helperText={descriptionError}
                            onChange={(event) => setDescriptionChecked(event.target.value)}
                            fullWidth={true}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onSave} variant="contained" color="primary">
                    {t(identifier ? "action.save" : "action.create")}
                </Button>
                <Button onClick={onClose} variant="contained" color="primary">
                    {t("action.cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
