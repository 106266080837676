export const isAudioLocked = () => {
    return new Promise((resolve) => {
        const checkHTML5Audio = async () => {
            const audio = new Audio()
            try {
                audio.play()
                resolve(false)
            } catch (err) {
                resolve(true)
            }
        }
        try {
            const context = new window.AudioContext()
            resolve(context.state === "suspended")
        } catch (e) {
            checkHTML5Audio()
        }
    })
}

export const runIfAudioLocked = (callback: () => void) => {
    isAudioLocked().then((locked) => {
        if (locked) {
            callback()
        }
    })
}
