import { Box, LinearProgress, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import useConfig from "../config/Provider"
import { staticURL } from "../config/urls"

export const LoadingPage = () => {
    const { mode } = useConfig()

    const { t } = useTranslation()

    return (
        <Stack direction="row" width="100%">
            <Box flexGrow={1} />
            <Stack height="100vh" flexGrow={0.5} spacing={0.5}>
                <Box flexGrow={1} />
                <Typography variant="button">{t("message.loading")}</Typography>
                <LinearProgress />
                <Stack direction="row">
                    <Box flexGrow={1} />
                    <Box
                        component="img"
                        sx={{
                            maxHeight: 38,
                            content: {
                                xs: `url(${staticURL(mode === "dark" ? "logo-small.svg" : "logo-small-light.svg")})`,
                                sm: `url(${staticURL(mode === "dark" ? "logo.svg" : "logo-light.svg")})`,
                            },
                        }}
                        alt="EyeTowers Logo"
                    />
                </Stack>
                <Box flexGrow={1} />
            </Stack>
            <Box flexGrow={1} />
        </Stack>
    )
}
