import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { request } from "../../config/headers"
import { endpointURL } from "../../config/urls"
import { User } from "../User"

export const userApi = createApi({
    reducerPath: "userApi",
    baseQuery: fetchBaseQuery({ ...request, baseUrl: endpointURL("") }),
    tagTypes: ["Users"],
    endpoints: (builder) => ({
        getCustomerUsers: builder.query<User[], number>({
            query: (customerID) => `customers/${customerID}/users`,
            providesTags: [{ type: "Users", id: "LIST" }],
        }),
    }),
})

export const { useGetCustomerUsersQuery } = userApi
