import { Settings } from "@mui/icons-material"
import { Box, Button, Grid, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Operation } from "../../api/Authz"
import { Site } from "../../api/Customer"
import { useSitePermission } from "../../auth/AuthorizerProvider"

export interface NewAccessProps {
    site: Site
}

export const NewAccess = (props: NewAccessProps) => {
    const { site } = props

    const { t } = useTranslation()
    const allowManageAccess = useSitePermission(Operation.MANAGE_SITE_ACCESS, site)

    return (
        <Stack sx={{ py: 2, px: 4, alignItems: "center", width: "100%" }}>
            <Box sx={{ width: "min(100%,1280px)" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("action.settings")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            startIcon={<Settings sx={{ mr: 0.5 }} />}
                            onClick={() => window.open(`https://acs.eyetowers.io`, "_blank")}
                            disabled={!allowManageAccess}
                        >
                            {t("access.management")}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Stack>
    )
}
