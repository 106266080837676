import { Close } from "@mui/icons-material"
import { Paper, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Actor, ActorType } from "../../api/Authz"
import { useGetGroupUsersQuery } from "../../api/portal/GroupUsers"
import { CustomerActors } from "../../api/portal/hooks/Actors"
import { IconText } from "./IconText"
import { UserChip } from "./UserChip"

export interface GroupTooltipProps {
    group: Actor
    customerActors: CustomerActors
}

export const GroupTooltip = (props: GroupTooltipProps) => {
    const { group, customerActors } = props

    const { t } = useTranslation()
    const groupUsers = useGetGroupUsersQuery(+group.id)

    return (
        <Stack m={1} spacing={1} alignItems="flex-start">
            <Typography variant="body2">{t("message.group_with_users")}</Typography>
            <Paper variant="outlined" sx={{ overflowY: "auto", maxHeight: 350, width: "100%" }}>
                <Stack m={1} spacing={1} alignItems="flex-start">
                    {groupUsers.data && groupUsers.data.length > 0 ? (
                        groupUsers.data?.map((u) => (
                            <UserChip
                                key={u.id}
                                user={{ type: ActorType.USER, id: u.id }}
                                customerActors={customerActors}
                            />
                        ))
                    ) : (
                        <IconText icon={<Close />} text={t("message.no_users")} italic />
                    )}
                </Stack>
            </Paper>
        </Stack>
    )
}
