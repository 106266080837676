import { CenterFocusStrong, SettingsApplications } from "@mui/icons-material"
import { Box, Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Operation } from "../../../api/Authz"
import { Unit } from "../../../api/Customer"
import { useCameraPermission } from "../../../auth/AuthorizerProvider"
import { ActionButton } from "./ActionButton"

export interface CameraActionsProps {
    admin: boolean
    unit: Unit
    cameraID: number
    onTarget: () => void
    onCalibrate: () => void
}

export function CameraActions(props: CameraActionsProps) {
    const { admin, unit, cameraID, onTarget, onCalibrate } = props

    const { t } = useTranslation()
    const allowedMove = useCameraPermission(Operation.MOVE_CAMERA, unit, cameraID)

    return (
        <Stack direction="row" spacing={0}>
            <Box flexGrow={1} />
            <ActionButton
                tooltip={t("action.target_camera_tooltip")}
                icon={<CenterFocusStrong />}
                disabled={!allowedMove}
                color="primary"
                onClick={onTarget}
            />
            <ActionButton
                tooltip={t("action.calibrate_camera_tooltip")}
                icon={<SettingsApplications />}
                disabled={!admin}
                color="secondary"
                onClick={onCalibrate}
            />
        </Stack>
    )
}
