import { Dangerous, Info } from "@mui/icons-material"
import { Box, Stack, Typography, useTheme } from "@mui/material"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDimensionsRef, useDocumentVisibilityState } from "rooks"
import adapter from "webrtc-adapter"
import { asCameraSubject, Operation } from "../../api/Authz"
import { CameraConfig, Unit } from "../../api/Customer"
import { IsLive, Playback } from "../../api/Video"
import useAuthorizer from "../../auth/AuthorizerProvider"
import useAuth from "../../auth/AuthProvider"
import { noSnackBar, rawHttp } from "../../backend/request"
import { request } from "../../config/headers"
import { hookInterval, hookTimeout, Milli, Second, Since } from "../../config/time"
import { eyeCastEndpointURL } from "../../config/urls"

const statsPoll = 500 * Milli
const bitratePoll = 1 * Second
const noFPSThreshold = 11 * Second
const reloadDelay = 5 * Second

export enum CameraState {
    Offline,
    Connecting,
    Connected,
}

export interface CameraProps {
    unit: Unit
    camera: CameraConfig
    region: string
    fitParent?: boolean
    disabled?: boolean
    playback: Playback
    delay?: number
    onVideoClick: () => void
    onStateChange?: (state: CameraState) => void
    onResolution?: (width: number, height: number) => void
    onBytesReceived?: (bytes: number) => void
    onFrames?: (frames: number) => void
}

export function Camera(props: CameraProps) {
    const {
        unit,
        camera,
        region,
        fitParent,
        disabled,
        playback,
        delay,
        onVideoClick,
        onStateChange,
        onResolution,
        onBytesReceived,
        onFrames,
    } = props

    const [generation, setGeneration] = useState(0)
    const [width, setWidth] = useState<number | undefined>(undefined)
    const [height, setHeight] = useState<number | undefined>(undefined)
    const [onPlayback, setOnPlayback] = useState<(playback: Playback) => void | undefined>()
    const [visible, setVisible] = useState(false)

    const theme = useTheme()
    const { t } = useTranslation()
    const { expired } = useAuth()
    const { allowOperation } = useAuthorizer()

    const streamingAuthorized = useMemo(
        () =>
            allowOperation(
                IsLive(playback) ? Operation.STREAM_CAMERA_LIVE : Operation.STREAM_CAMERA_ARCHIVE,
                asCameraSubject(unit, camera.ID)
            ),
        [allowOperation, unit, playback, camera.ID]
    )
    const allowStream = useMemo(() => !expired && streamingAuthorized, [expired, streamingAuthorized])

    const videoRef = useRef<HTMLVideoElement>(null)

    const snackbar = noSnackBar

    const reloadVideo = useCallback(() => setTimeout(() => setGeneration((g) => g + 1), reloadDelay), [])

    const pageVisibility = useDocumentVisibilityState()
    const isVisible = useMemo(() => pageVisibility === "visible", [pageVisibility])

    useEffect(() => {
        if (!isVisible) {
            setVisible(false)
            return
        }
        return hookTimeout(() => setVisible(true), delay || 0)
    }, [isVisible, delay])

    // On iOS devices, when the app goes to background and back, the video may get
    // paused. Let's make sure it keeps playing by calling play() on it.
    useEffect(() => {
        // Make sure to check bot visible and isVisible to ensure that:
        //  - the video element already exists (visible), and
        //  - we run play() even if we were in background for a short time (isVisible).
        if (visible) {
            videoRef.current?.play().catch((e) => {
                console.log(`Cam${camera.ID} play() command interrupted.`, e)
            })
        }
    }, [visible, videoRef, camera])

    useEffect(() => {
        if (disabled || !allowStream || !videoRef.current || !unit || !camera || !visible) {
            return
        }

        console.log(
            `Cam${camera.ID}: Initializing connection for browser: "${JSON.stringify(
                adapter.browserDetails
            )}" (generation: ${generation}).`
        )

        const videoNode = videoRef.current
        let stream: MediaStream | null = new MediaStream()
        // No STUN configuration since we only use local candidates for the Web client.
        let pc: RTCPeerConnection | null = new RTCPeerConnection()
        let offer: RTCSessionDescriptionInit | null = null
        let exchanged = false
        let reloaded = false
        const reload = () => {
            if (reloaded) {
                console.log(`Cam${camera.ID}: Reload already triggered (generation: ${generation}).`)
                return
            }
            console.log(`Cam${camera.ID}: Reloading (generation: ${generation}).`)
            reloaded = true
            reloadVideo()
        }
        const getRemoteSdp = () => {
            if (!pc || !pc.localDescription || pc.signalingState === "closed") {
                return
            }
            const offer = btoa(pc.localDescription.sdp)

            rawHttp("Posting SDP", eyeCastEndpointURL(unit, camera.ID, region), noSnackBar, {
                method: "POST",
                body: offer,
                headers: request.headers,
            })
                .then((data) => {
                    if (pc && pc.signalingState !== "closed") {
                        pc.setRemoteDescription(
                            new RTCSessionDescription({
                                type: "answer",
                                sdp: atob(data),
                            })
                        ).then(() => console.log(`Cam${camera.ID}: Remote description set.`))
                    }
                })
                .catch((e) => {
                    console.log(`Cam${camera.ID}: Error exchanging offers: "${e.string}".`)
                    reload()
                })
        }

        let lastFrames = 0
        let lastValidFrame = new Date()
        let paused = false
        const fpsWatchdog = (totalFrames: number) => {
            const newFrames = totalFrames - lastFrames
            lastFrames = totalFrames
            if (newFrames !== 0 || paused) {
                lastValidFrame = new Date()
                return
            }
            if (Since(lastValidFrame) > noFPSThreshold) {
                console.log(`Cam${camera.ID}: No decoded frames for too long, reloading.`)
                reload()
            }
        }

        const exchangeOffers = () => {
            if (!exchanged && offer && pc && pc.connectionState !== "closed" && pc.connectionState !== "failed") {
                exchanged = true
                console.log(`Cam${camera.ID}: Local description set.`)
                getRemoteSdp()
                console.log(`Cam${camera.ID}: Sent offer tp remote.`)
            }
        }

        if (onStateChange) {
            onStateChange(CameraState.Connecting)
        }
        let channel: RTCDataChannel | null = pc.createDataChannel("control", { id: 1, negotiated: true })
        channel.onopen = () => {
            console.log(`Cam${camera.ID}: Control channel open.`)
            setOnPlayback(() => (p: Playback) => {
                paused = p.Paused
                channel?.send(JSON.stringify(p))
            })
        }
        channel.onmessage = (event) => {
            interface StreamMessage {
                Type: string
            }
            console.log(`Cam${camera.ID}: event`, JSON.stringify(event))
            const message = JSON.parse(event.data as string) as StreamMessage
            if (message?.Type === "Reconnect") {
                console.log(`Cam${camera.ID}: Server-side reconnection request.`)
                reload()
            }
        }

        pc.onicegatheringstatechange = () => {
            console.log(`Cam${camera.ID}: ICE gathering state change: "${pc?.iceGatheringState}".`)
            if (pc?.iceGatheringState === "complete" && offer) {
                exchangeOffers()
            }
        }

        pc.onnegotiationneeded = async () => {
            if (!pc) {
                console.log(`Cam${camera.ID}: Negotiation needed, but connection lost.`)
                return
            }
            console.log(`Cam${camera.ID}: Negotiation needed: "${pc.iceGatheringState}".`)
            offer = await pc.createOffer({
                iceRestart: false,
                offerToReceiveVideo: true,
                offerToReceiveAudio: false,
            })
            if (!pc) {
                console.log(`Cam${camera.ID}: Offer created, but connection lost.`)
                return
            }
            console.log(`Cam${camera.ID}: Offer created (type=${offer.type}):\n${offer.sdp}`)
            await pc.setLocalDescription(offer)
        }

        pc.ontrack = (event) => {
            stream?.getTracks().forEach((t) => stream?.removeTrack(t))
            stream?.addTrack(event.track)
            console.log(`Cam${camera.ID}: Track with ${event.streams.length} streams delivered.`)
        }

        pc.onsignalingstatechange = () => {
            console.log(`Cam${camera.ID}: Signaling state change: "${pc?.signalingState}".`)
            if (pc?.signalingState === "closed") {
                reload()
            }
        }

        pc.onconnectionstatechange = () => {
            console.log(`Cam${camera.ID}: Connection state change: "${pc?.connectionState}".`)
            if (pc?.connectionState === "failed") {
                reload()
            }
            if (pc?.connectionState === "connected") {
                console.log(`Cam${camera.ID}: Stream forwarded to HTML video element.`)
                videoNode.srcObject = stream
            }
        }

        pc.oniceconnectionstatechange = () => {
            console.log(`Cam${camera.ID}: ICE connection state change: "${pc?.iceConnectionState}".`)
            if (pc?.iceConnectionState === "failed") {
                reload()
            }
        }

        const startStatsChecker = () => {
            const interval = setInterval(() => {
                if (!pc) {
                    return
                }
                pc.getStats(stream?.getVideoTracks()[0]).then((stat) =>
                    stat.forEach((v) => {
                        if ((v as RTCStats).type !== "inbound-rtp") {
                            // Not the stats we are interested.
                            return
                        }
                        const rtpStats = v as RTCInboundRtpStreamStats
                        if (!rtpStats.framesDecoded) {
                            // No frames decoded yet, so the stream is not fully up.
                            return
                        }
                        console.log("H264 stream loaded, disabling MJPEG fallback.")
                        if (onStateChange) {
                            onStateChange(CameraState.Connected)
                        }
                        const width = stream?.getTracks()[0].getSettings().width
                        const height = stream?.getTracks()[0].getSettings().height
                        if (onResolution && width && height) {
                            onResolution(width, height)
                        }
                        clearInterval(interval)
                    })
                )
            }, statsPoll)
            return () => clearInterval(interval)
        }
        const stopStatsChecker = startStatsChecker()

        const stopBitrateChecker = hookInterval(() => {
            if (!pc) {
                return
            }
            pc.getStats(stream?.getVideoTracks()[0]).then((stat) =>
                stat.forEach((v) => {
                    if ((v as RTCStats).type === "transport") {
                        const stats = v as RTCTransportStats
                        if (!stats.bytesReceived) {
                            // No bytes received yet.
                            return
                        }
                        if (onBytesReceived) {
                            onBytesReceived(stats.bytesReceived)
                        }
                    }
                    if ((v as RTCStats).type === "inbound-rtp") {
                        const rtpStats = v as RTCInboundRtpStreamStats
                        if (!rtpStats.framesDecoded) {
                            // No frames decoded yet.
                            return
                        }
                        if (onFrames) {
                            onFrames(rtpStats.framesDecoded)
                        }
                        fpsWatchdog(rtpStats.framesDecoded)
                    }
                })
            )
        }, bitratePoll)

        return () => {
            console.log(`Cam${camera.ID}: (Un/re)mounting closing (generation: ${generation}).`)
            channel?.close()
            channel = null
            setOnPlayback(undefined)
            stopStatsChecker()
            stopBitrateChecker()
            videoNode.srcObject = null
            stream?.getTracks().forEach((t) => stream?.removeTrack(t))
            stream = null
            pc?.close()
            pc = null
            if (onStateChange) {
                onStateChange(CameraState.Offline)
            }
        }
    }, [
        snackbar,
        disabled,
        allowStream,
        reloadVideo,
        generation,
        camera,
        unit,
        region,
        onStateChange,
        onResolution,
        onBytesReceived,
        onFrames,
        visible,
    ])

    const [wrapperRef, dimensions, element] = useDimensionsRef()

    useEffect(() => {
        setWidth(dimensions?.width)
        setHeight(dimensions?.height)
    }, [dimensions?.height, dimensions?.width, camera])

    const tryUpdateSize = useCallback(() => {
        const box = element?.getBoundingClientRect()
        if (box && (width !== box?.width || height !== box.height)) {
            setWidth(box.width)
            setHeight(box.height)
        }
    }, [width, height, element])

    useEffect(() => {
        // Make sure that we eventually adjust the video size even if we don't get the
        // corresponding notification.
        const l = () => window.requestAnimationFrame(tryUpdateSize)
        const stop = hookInterval(l, 100)
        window.addEventListener("fullscreenchange", l)

        return () => {
            stop()
            window.removeEventListener("fullscreenchange", l)
        }
    }, [tryUpdateSize])

    useEffect(() => {
        if (onPlayback === undefined) {
            console.debug("Control channel not set up yet, ignoring the playback command.")
            return
        }
        console.info("Playback comand sent", playback)
        onPlayback(playback)
    }, [playback, onPlayback])

    const renderMessage = (message: string, error?: boolean) => (
        <Stack direction="row" alignItems="center" spacing={1}>
            {error ? <Dangerous htmlColor={theme.palette.grey[600]} /> : <Info htmlColor={theme.palette.grey[600]} />}
            <Typography color={theme.palette.grey[600]} variant="body2" fontWeight="bold">
                {message}
            </Typography>
        </Stack>
    )

    return (
        <Stack
            width="100%"
            height="100%"
            sx={{
                position: "relative",
                display: "flex",
            }}
        >
            <Box ref={wrapperRef as React.Ref<unknown>} style={{ width: "100%", height: "100%" }}>
                {(disabled || !allowStream || !visible) && (
                    <Box
                        style={{ position: fitParent ? "absolute" : "relative", display: "flex" }}
                        width={fitParent ? width : "100%"}
                        height={fitParent ? height : "100%"}
                        overflow="hidden"
                        minHeight={48}
                        minWidth={48}
                        alignItems="center"
                        justifyContent="center"
                    >
                        {disabled
                            ? renderMessage(t("message.disabled_stream"))
                            : !allowStream
                            ? renderMessage(t("message.forbidden_stream"), true)
                            : !visible
                            ? renderMessage(t("message.inactive_stream"))
                            : null}
                    </Box>
                )}
                <video
                    key={`cam-video-target-${camera.ID}`}
                    style={{ position: fitParent ? "absolute" : "relative", display: "flex" }}
                    ref={videoRef}
                    muted={true}
                    autoPlay={true}
                    playsInline={true}
                    controls={false}
                    disablePictureInPicture
                    onClick={onVideoClick}
                    width={fitParent ? width : "100%"}
                    height={fitParent ? height : "100%"}
                />
            </Box>
        </Stack>
    )
}
