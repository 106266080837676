import { Check } from "@mui/icons-material"
import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { ETDialogTitle } from "./ETDialogTitle"

export interface RedirectDialogProps {
    url: string
}

export const RedirectDialog = (props: RedirectDialogProps) => {
    const { url } = props

    const { t } = useTranslation()

    const onClickConfirm = () => {
        window.location.href = url
    }

    return (
        <Dialog aria-labelledby="redirect-dialog-title" maxWidth="xs" open fullWidth>
            <ETDialogTitle />
            <DialogContent>
                <Typography>{t("message.proceed_to_password_reset")}</Typography>
            </DialogContent>
            <DialogActions>
                <Button startIcon={<Check />} onClick={onClickConfirm} variant="contained" color="primary">
                    {t("action.ok")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
