import { createContext, ReactNode, useContext, useEffect, useMemo } from "react"
import { useGetVersionQuery } from "../api/portal/Version"
import { hookInterval, Minute } from "../config/time"
import { version } from "../config/version"

const versionCheckInterval = 5 * Minute

interface VersionContextType {
    upgraded: boolean
    serverVersion: string
    clientVersion: string
}

const VersionContext = createContext<VersionContextType>({} as VersionContextType)

export const VersionProvider = ({ children }: { children: ReactNode }) => {
    const versionQuery = useGetVersionQuery()

    useEffect(() => hookInterval(versionQuery.refetch, versionCheckInterval), [versionQuery.refetch])

    const clientVersion = version || "unknown"
    const serverVersion = useMemo(() => versionQuery.data?.Version || clientVersion, [versionQuery.data, clientVersion])
    const upgraded = useMemo(
        () => versionQuery.data !== undefined && serverVersion !== clientVersion,
        [versionQuery.data, clientVersion, serverVersion]
    )

    return (
        <VersionContext.Provider value={{ upgraded, serverVersion, clientVersion }}>{children}</VersionContext.Provider>
    )
}

const useVersion = () => useContext(VersionContext)
export default useVersion
