import { History, NotificationsActive } from "@mui/icons-material"
import { alpha, darken, lighten, Stack, styled, Switch, Theme, Tooltip, Typography, useTheme } from "@mui/material"
import { t } from "i18next"
import { NotificationIcon } from "./NotificationIcon"

export interface PartitionInfoProps {
    armed: boolean
    alarm: boolean
    alarmInMemory: boolean
    disabled: boolean
    flipArmed: (on: boolean) => void
}

const suppress = (theme: Theme, color: string, coefficient?: number) =>
    theme.palette.mode === "dark" ? darken(color, coefficient || 0.5) : lighten(color, coefficient || 0.5)

const ArmSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase": {
        color: theme.palette.success.main,
        "&:hover": {
            backgroundColor: alpha(theme.palette.success.main, theme.palette.action.hoverOpacity),
        },
    },
    "& .MuiSwitch-switchBase.Mui-disabled": {
        color: suppress(theme, theme.palette.success.main),
    },
    "& .MuiSwitch-switchBase + .MuiSwitch-track": {
        backgroundColor: theme.palette.success.light,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
        color: theme.palette.error.main,
        "&:hover": {
            backgroundColor: alpha(theme.palette.error.main, theme.palette.action.hoverOpacity),
        },
    },
    "& .MuiSwitch-switchBase.Mui-checked.Mui-disabled": {
        color: suppress(theme, theme.palette.error.main),
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        backgroundColor: theme.palette.error.light,
    },
}))

export const PartitionInfo = (props: PartitionInfoProps) => {
    const { armed, alarm, alarmInMemory, disabled, flipArmed } = props
    const theme = useTheme()

    const currentState = armed ? t("alarm.armed") : t("alarm.disarmed")
    const actionTooltip = armed ? t("action.disarm_tooltip") : t("action.arm_tooltip")

    return (
        <Stack
            direction="row"
            px={1}
            height="100%"
            sx={{
                alignItems: "center",
                backgroundColor: theme.palette.background.paper,
            }}
        >
            <Stack
                direction="row"
                sx={{
                    alignItems: "center",
                    flexGrow: 1,
                    overflow: "hidden",
                }}
            >
                <Tooltip title={actionTooltip} disableInteractive>
                    <ArmSwitch checked={armed} disabled={disabled} onClick={() => flipArmed(armed)} />
                </Tooltip>
                <Tooltip title={currentState} disableInteractive>
                    <Typography
                        sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {currentState}
                    </Typography>
                </Tooltip>
            </Stack>

            <Stack
                direction="row"
                sx={{
                    alignItems: "right",
                }}
            >
                <NotificationIcon
                    onTooltip={t("alarm.alarm_active")}
                    offTooltip={t("alarm.alarm_inactive")}
                    condition={alarm}
                    iconCtor={(p) => <NotificationsActive sx={p} />}
                />
                <NotificationIcon
                    onTooltip={t("alarm.in_memory_active")}
                    offTooltip={t("alarm.in_memory_inactive")}
                    condition={alarmInMemory}
                    iconCtor={(p) => <History sx={p} />}
                />
            </Stack>
        </Stack>
    )
}
