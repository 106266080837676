import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { asUnitSubject, Operation } from "../api/Authz"
import { Customer, Site } from "../api/Customer"
import { WhoAmI } from "../api/WhoAmI"
import useAuthorizer from "../auth/AuthorizerProvider"
import { SitePage } from "../components/SitePage"
import { Video } from "./Video"

export type SiteVideoProps = {
    whoAmI: WhoAmI
    customers: Customer[]
    showArchived: boolean
    setShowArchived: (value: boolean) => void
}

export const SiteVideo = (props: SiteVideoProps) => {
    const { whoAmI, customers, showArchived, setShowArchived } = props

    const { siteID } = useParams()

    const site = useMemo(
        () => customers.flatMap((c) => c.Sites).find((s) => s.ID === parseInt(siteID || "-1")),
        [customers, siteID]
    )
    const customer = useMemo(() => customers.find((c) => c.ID === site?.CustomerID), [customers, site])
    const { allowOperation } = useAuthorizer()
    const units = useMemo(
        () =>
            customers
                .flatMap((c) => c.Units)
                .filter((u) => u.SiteID === site?.ID && allowOperation(Operation.UI_VIEW_VIDEO, asUnitSubject(u))),
        [customers, site, allowOperation]
    )
    const allowed = units.length > 0

    return (
        <SitePage
            whoAmI={whoAmI}
            units={units}
            site={site}
            customer={customer}
            customers={customers}
            allowed={allowed}
            showArchived={showArchived}
            setShowArchived={setShowArchived}
            render={(site: Site) => <Video site={site} units={units} />}
        />
    )
}
