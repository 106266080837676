import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material"
import { useConfirm } from "material-ui-confirm"
import { useSnackbar } from "notistack"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Operation } from "../api/Authz"
import { Identifier, identifierName, identifierTypeElement } from "../api/Identifier"
import { User } from "../api/User"
import { useCustomerPermission } from "../auth/AuthorizerProvider"
import { http } from "../backend/request"
import { request } from "../config/headers"
import { endpointURL } from "../config/urls"
import { formatUserLong } from "../widgets/format"
import { AssignIdentifierDialog } from "./AssignIdentifierDialog"
import { IdentifierActions } from "./IdentifierActions"
import { IdentifierDialog } from "./IdentifierDialog"

export interface UserIdentifierManagementDialogProps {
    customerID: number
    user?: User
    onClose: () => void
}

export function UserIdentifierManagementDialog(props: UserIdentifierManagementDialogProps) {
    const { customerID, user, onClose } = props
    const [loaded, setLoaded] = React.useState(false)
    const [error, setError] = React.useState<any>()
    const [identifiers, setIdentifiers] = React.useState<Identifier[]>([])
    const [identifierDialogOpen, setIdentifierDialogOpen] = React.useState<boolean>(false)
    const [assigningDialogOpen, setAssigningDialogOpen] = React.useState<boolean>(false)
    const [editingIdentifier, setEditingIdentifier] = React.useState<Identifier | undefined>(undefined)

    const { t } = useTranslation()
    const confirm = useConfirm()
    const snackbar = useSnackbar()

    const allowCreate = useCustomerPermission(Operation.CREATE_CUSTOMER_IDENTIFIER, customerID)
    const allowEdit = useCustomerPermission(Operation.UPDATE_CUSTOMER_IDENTIFIER, customerID)
    const allowDelete = useCustomerPermission(Operation.DELETE_CUSTOMER_IDENTIFIER, customerID)

    useEffect(() => {
        if (customerID) {
            setLoaded(false)
            setError(null)
            Promise.all([
                http<Identifier[]>(
                    "Loading customer identifiers",
                    endpointURL(`customers/${customerID}/identifiers`),
                    snackbar,
                    request
                ).then((result) => setIdentifiers(result), setError),
            ]).then(() => setLoaded(true))
        }
    }, [customerID, snackbar])

    const onEdit = (identifier: Identifier) => {
        setEditingIdentifier(identifier)
        setIdentifierDialogOpen(true)
    }

    const onNew = () => {
        setEditingIdentifier(undefined)
        setIdentifierDialogOpen(true)
    }

    const onCreate = (identifier: Identifier) => {
        http<Identifier>(
            `Creating identifier '${identifier.Type}/${identifier.Value}'`,
            endpointURL(`identifiers`),
            snackbar,
            {
                method: "POST",
                headers: request.headers,
                body: JSON.stringify(identifier),
            }
        )
            .then((result) => {
                setIdentifiers((ids) => ids.concat(result))
                onAssign(result)
            })
            .catch((e) => console.log(e))
            .finally(() => setIdentifierDialogOpen(false))
    }

    const onUpdate = (identifier: Identifier) => {
        http<Identifier>(
            `Updating identifier '${identifier.Type}/${identifier.Value}'`,
            endpointURL(`identifiers/${identifier.ID}`),
            snackbar,
            {
                method: "PUT",
                headers: request.headers,
                body: JSON.stringify(identifier),
            }
        )
            .then((result) => setIdentifiers((ids) => ids.map((i) => (i.ID === identifier.ID ? result : i))))
            .catch((e) => console.log(e))
            .finally(() => setIdentifierDialogOpen(false))
    }

    const onDelete = (identifier: Identifier) => {
        confirm({
            title: t("confirm.generic"),
            description: t("confirm.delete_identifier", {
                identifier: `${identifierName(identifier.Type, t)}: ${identifier.Value}`,
            }),
            cancellationText: t("action.cancel"),
            confirmationText: t("action.delete"),
            confirmationButtonProps: {
                color: "secondary",
            },
        })
            .then(() => {
                http<any>(
                    `Deleting identifier '${identifier.Type}/${identifier.Value}'`,
                    endpointURL(`identifiers/${identifier.ID}`),
                    snackbar,
                    {
                        method: "DELETE",
                        headers: request.headers,
                    }
                )
                    .then(() => setIdentifiers((ids) => ids.filter((i) => i.ID !== identifier.ID)))
                    .catch((e) => console.log(e))
            })
            .catch(() => {})
    }

    const onAssign = (identifier: Identifier) => {
        http<Identifier>(
            `Assigning identifier '${identifier.Type}/${identifier.Value}' to user ${formatUserLong(t, user)}`,
            endpointURL(`identifiers/${identifier.ID}/assignments`),
            snackbar,
            {
                method: "PUT",
                headers: request.headers,
                body: JSON.stringify({
                    UserID: user?.ID,
                }),
            }
        )
            .then((result) => {
                setIdentifiers((ids) => ids.map((i) => (i.ID === identifier.ID ? result : i)))
            })
            .catch((e) => console.log(e))
    }

    const onUnassign = (identifier: Identifier) => {
        http<Identifier>(
            `Unassigning identifier '${identifier.Type}/${identifier.Value}'`,
            endpointURL(`identifiers/${identifier.ID}/assignments`),
            snackbar,
            {
                method: "DELETE",
                headers: request.headers,
            }
        )
            .then((result) => setIdentifiers((ids) => ids.map((i) => (i.ID === identifier.ID ? result : i))))
            .catch((e) => console.log(e))
    }

    const identifierRow = (identifier: Identifier) => {
        return (
            <TableRow key={identifier.ID}>
                <TableCell>{identifierTypeElement(identifier.Type, t)}</TableCell>
                <TableCell>{identifier.Value}</TableCell>
                <TableCell>{identifier.Description}</TableCell>
                <TableCell align="right">
                    <IdentifierActions
                        allowEdit={allowEdit}
                        allowDelete={allowDelete}
                        allowUnassign={allowEdit}
                        onEdit={() => onEdit(identifier)}
                        onDelete={() => onDelete(identifier)}
                        onUnassign={() => onUnassign(identifier)}
                    />
                </TableCell>
            </TableRow>
        )
    }

    return (
        <Dialog onClose={onClose} aria-labelledby="user-identifier-management-dialog-title" maxWidth="md" open={!!user}>
            <DialogTitle id="user-identifier-management-dialog-title">
                {t("dialog.user_identifier_management.title")}
            </DialogTitle>
            {error && <Typography>{t("message.no_identifiers")}</Typography>}
            {!loaded && !error && <Typography>{t("message.loading")}</Typography>}
            {loaded && !error && (
                <TableContainer component="div" style={{ overflow: "auto", paddingBottom: 5, maxHeight: "75%" }}>
                    <Table aria-label="identifiers table" size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("field.type")}</TableCell>
                                <TableCell>{t("field.value")}</TableCell>
                                <TableCell>{t("field.description")}</TableCell>
                                <TableCell>{t("field.actions")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {identifiers.filter((i) => i.UserID === user?.ID).map((i) => identifierRow(i))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <DialogActions>
                <Button onClick={onNew} variant="contained" color="primary" disabled={!allowCreate}>
                    {t("action.new_identifier")}
                </Button>
                <Button
                    onClick={() => setAssigningDialogOpen(true)}
                    variant="contained"
                    color="primary"
                    disabled={!identifiers.some((i) => !i.UserID) || !allowEdit}
                >
                    {t("action.assign")}
                </Button>
                <Button onClick={onClose} variant="contained" color="primary">
                    {t("action.close")}
                </Button>
            </DialogActions>
            <IdentifierDialog
                open={identifierDialogOpen}
                identifier={editingIdentifier}
                customerID={customerID}
                onClose={() => setIdentifierDialogOpen(false)}
                onUpdate={onUpdate}
                onCreate={onCreate}
            />
            <AssignIdentifierDialog
                open={assigningDialogOpen}
                identifiers={identifiers.filter((i) => !i.UserID)}
                onAssigned={(i) => {
                    onAssign(i)
                    setAssigningDialogOpen(false)
                }}
                onClose={() => setAssigningDialogOpen(false)}
            />
        </Dialog>
    )
}
