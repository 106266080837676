import { Call, CreditCard, DeviceUnknown, DirectionsCar } from "@mui/icons-material"
import { Typography } from "@mui/material"
import { TFunction } from "i18next"

export const Phone = "Phone"
export const KeyCard = "KeyCard"
export const LicensePlate = "LicensePlate"

export const identifierTypes = () => [Phone, KeyCard, LicensePlate]

export const identifierName = (identifier: string, t: TFunction) => {
    switch (identifier) {
        case Phone:
            return t("identifier.phone")
        case KeyCard:
            return t("identifier.key_card")
        case LicensePlate:
            return t("identifier.license_plate")
    }
    return "unknown"
}

export interface Identifier {
    ID: number
    Type: string
    Value: string
    Description: string
    CustomerID: number
    UserID?: number
}

export const identifierElement = (identifier: Identifier) => {
    return identifierTypeElementWithText(identifier.Type, identifier.Value)
}

export const identifierTypeElement = (type: string, t: TFunction) => {
    return identifierTypeElementWithText(type, identifierName(type, t))
}
const identifierTypeElementWithText = (type: string, text: string) => {
    return (
        <Typography
            style={{
                fontSize: "small",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
            }}
        >
            {identifierIcon(type)}&nbsp;{text}
        </Typography>
    )
}

export const identifierIcon = (type: string) => {
    if (type === Phone) {
        return <Call />
    }
    if (type === KeyCard) {
        return <CreditCard />
    }
    if (type === LicensePlate) {
        return <DirectionsCar />
    }
    return <DeviceUnknown />
}
