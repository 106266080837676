import { Stack, Tooltip } from "@mui/material"
import { ReactElement, cloneElement } from "react"

export interface CameraTagProps {
    icon: ReactElement
    tooltip: string
}

export const CameraTag = (props: CameraTagProps) => {
    const { icon, tooltip } = props

    return (
        <Tooltip title={tooltip} disableInteractive>
            <Stack
                sx={{
                    backgroundColor: "red",
                    borderRadius: 0.5,
                    color: "black",
                    mixBlendMode: "screen",
                }}
            >
                {cloneElement(icon, { fontSize: "small" })}
            </Stack>
        </Tooltip>
    )
}
