import { Typography } from "@mui/material"
import { useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import useAuth from "../auth/AuthProvider"
import { SetPasswordDialog } from "../components/SetPasswordDialog"

export const Password = () => {
    const [messages, setMessages] = useState<string[]>([])
    const [searchParams] = useSearchParams()
    const flow = searchParams.get("flow")
    const { whoAmI, setPassword } = useAuth()
    const navigate = useNavigate()

    if (!flow) {
        return <Typography>Missing flow id.</Typography>
    }

    return (
        <SetPasswordDialog
            actor={whoAmI?.User}
            messages={messages}
            onSetPassword={(password) => setPassword(flow, password, setMessages, navigate)}
        />
    )
}
