import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { Customer, Site, Unit } from "../api/Customer"
import { WhoAmI } from "../api/WhoAmI"
import { minPageWidth } from "../config/sizing"
import { PageError } from "./PageError"
import { UnitAppBar } from "./UnitAppBar"

export interface UnitPageProps {
    whoAmI: WhoAmI
    unit?: Unit
    site?: Site
    customer?: Customer
    customers: Customer[]
    minRevision?: number
    maxRevision?: number
    allowed: boolean
    showArchived: boolean
    setShowArchived: (value: boolean) => void
    render: (unit: Unit, site: Site) => React.ReactNode
}

export const UnitPage = (props: UnitPageProps) => {
    const {
        whoAmI,
        unit,
        site,
        customer,
        customers,
        minRevision,
        maxRevision,
        allowed,
        render,
        showArchived,
        setShowArchived,
    } = props

    const { shortName } = useParams()
    const { t } = useTranslation()

    const renderBody = () => {
        if (!allowed) {
            return <PageError message={t("message.forbidden_page")} />
        }
        if (!unit) {
            return <PageError message={t("message.unit_not_found", { unit: shortName })} />
        }
        if (!site) {
            return <PageError message={t("message.site_not_found", { id: unit?.SiteID })} />
        }
        if ((minRevision && unit.Revision < minRevision) || (maxRevision && unit.Revision > maxRevision)) {
            return <PageError message={t("message.service_not_supported")} />
        }
        return render(unit, site)
    }

    return (
        <Box sx={{ display: "flex", width: "100%", height: "100%", minWidth: minPageWidth, flexDirection: "column" }}>
            <UnitAppBar
                whoAmI={whoAmI}
                unit={unit}
                site={site}
                customer={customer}
                customers={customers}
                showArchived={showArchived}
                setShowArchived={setShowArchived}
            />
            {renderBody()}
        </Box>
    )
}
