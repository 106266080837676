import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material"
import { LatLngBounds } from "leaflet"
import { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useMap } from "react-leaflet"
import { ToShortLocalDateTime } from "../../../config/time"
import { Device } from "../api/Device"
import { GPS, formatGPS, toLeafletPosition } from "../api/GPS"
import { DeviceActions } from "./DeviceActions"

export interface DevicesDialogProps {
    open: boolean
    admin: boolean
    moveCameras: boolean
    devices: Device[]
    devicePositions: { [id: string]: GPS }
    onClose: () => void
    onPickDevicePosition: (device: Device) => void
    onClearDevicePosition: (device: Device) => void
    onTargetDevice: (device: Device) => void
    onEditDevice: (device: Device) => void
    onDeleteDevice: (device: Device) => void
    onNewDevice: () => void
}

export function DevicesDialog(props: DevicesDialogProps) {
    const {
        open,
        admin,
        moveCameras,
        devices,
        devicePositions,
        onClose,
        onPickDevicePosition,
        onClearDevicePosition,
        onTargetDevice,
        onEditDevice,
        onDeleteDevice,
        onNewDevice,
    } = props

    const { t } = useTranslation()

    const map = useMap()

    const onShowAll = () => {
        showAll()
        onClose()
    }

    const showAll = useCallback(() => {
        var minLat = 0
        var minLng = 0
        var maxLat = 0
        var maxLng = 0
        var empty = true

        devices.forEach((device) => {
            const devPos = devicePositions[device.ID]
            if (devPos) {
                if (empty) {
                    empty = false
                    minLat = maxLat = devPos.Latitude
                    minLng = maxLng = devPos.Longitude
                } else {
                    minLat = Math.min(minLat, devPos.Latitude)
                    minLng = Math.min(minLng, devPos.Longitude)
                    maxLat = Math.max(maxLat, devPos.Latitude)
                    maxLng = Math.max(maxLng, devPos.Longitude)
                }
            }
        })
        map.fitBounds(new LatLngBounds({ lat: minLat, lng: minLng }, { lat: maxLat, lng: maxLng }).pad(0.05), {
            maxZoom: 19,
        })
    }, [map, devices, devicePositions])

    useEffect(() => {
        showAll()
    }, [showAll])

    const onFocusDevice = (device: Device) => {
        const devPos = devicePositions[device.ID]
        if (devPos) {
            map.flyTo(toLeafletPosition(devPos))
        }
        onClose()
    }

    const deviceRow = (device: Device) => {
        const position = devicePositions[device.ID]
        const hasPosition = !!position
        return (
            <TableRow key={device.ID}>
                <TableCell component="th" scope="row">
                    {device.ID}
                </TableCell>
                <TableCell component="th" scope="row">
                    {device.IMEI}
                </TableCell>
                <TableCell component="th" scope="row" sx={{ textWrap: "nowrap" }}>
                    {device.Name}
                </TableCell>
                <TableCell align="right" sx={{ textWrap: "nowrap" }}>
                    {hasPosition ? formatGPS(position) : "unknown"}
                </TableCell>
                <TableCell align="right" sx={{ textWrap: "nowrap" }}>
                    {hasPosition ? ToShortLocalDateTime(new Date(position.CreatedAt)) : "unknown"}
                </TableCell>
                <TableCell align="right">
                    <DeviceActions
                        admin={admin}
                        moveCameras={moveCameras}
                        hasPosition={hasPosition}
                        onPickDevicePosition={() => onPickDevicePosition(device)}
                        onClearDevicePosition={() => onClearDevicePosition(device)}
                        onFocusDevice={() => onFocusDevice(device)}
                        onTargetDevice={() => onTargetDevice(device)}
                        onEditDevice={() => onEditDevice(device)}
                        onDeleteDevice={() => onDeleteDevice(device)}
                    />
                </TableCell>
            </TableRow>
        )
    }

    return (
        <Dialog onClose={onClose} aria-labelledby="devices-dialog-title" maxWidth="lg" fullWidth open={open}>
            <DialogTitle id="devices-dialog-title">{t("tracking.targets")}</DialogTitle>

            <TableContainer component="div" style={{ overflow: "auto", paddingBottom: 5, maxHeight: "75%" }}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("field.id")}</TableCell>
                            <TableCell>{t("field.imei")}</TableCell>
                            <TableCell>{t("field.name")}</TableCell>
                            <TableCell align="right">{t("field.gps")}</TableCell>
                            <TableCell align="right">{t("field.updated")}</TableCell>
                            <TableCell align="right">{t("field.actions")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{devices.map(deviceRow)}</TableBody>
                </Table>
            </TableContainer>
            <DialogActions>
                <Button onClick={onShowAll} variant="contained" color="primary">
                    {t("action.show_all")}
                </Button>
                {admin && (
                    <Button onClick={onNewDevice} variant="contained" color="primary">
                        {t("action.new_target")}
                    </Button>
                )}
                <Button onClick={onClose} variant="contained" color="primary">
                    {t("action.close")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
