import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Target } from "../api/PTZ"

export interface PickTargetDialogProps {
    open: boolean
    targets: Target[]
    onClose: () => void
    onPicked: (Target: Target) => void
    parent?: Element
}

export function PickTargetDialog(props: PickTargetDialogProps) {
    const { open, targets, onClose, onPicked, parent } = props

    const { t } = useTranslation()

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="pick-target-dialog-title"
            maxWidth="lg"
            fullWidth
            open={open}
            container={parent}
        >
            <DialogTitle id="pick-target-dialog-title">{t("dialog.pick_target.title")}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    {targets.map((t) => (
                        <Grid key={t.ID} item xs={12} sm={6} md={3} lg={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={() => onPicked(t)}
                                fullWidth
                            >
                                {t.Name}
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained" color="primary">
                    {t("action.cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
