import { Tooltip, useTheme } from "@mui/material"
import { SxProps, Theme } from "@mui/system"
import { emphasized } from "../../theme/Theme"

export interface NotificationIconProps {
    iconCtor: (props: SxProps<Theme>) => React.ReactElement
    onTooltip: string
    offTooltip: string
    condition: boolean
}

export const NotificationIcon = (props: NotificationIconProps) => {
    const { iconCtor, onTooltip, offTooltip, condition } = props
    const theme = useTheme()

    const notificationColor = (condition: boolean) => (condition ? theme.palette.error.main : emphasized(0.2, theme))

    return (
        <Tooltip title={condition ? onTooltip : offTooltip} disableInteractive>
            {iconCtor({ color: notificationColor(condition) })}
        </Tooltip>
    )
}
