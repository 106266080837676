import { History, PlayArrow } from "@mui/icons-material"
import { Slider, Stack, Typography, useTheme } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useIntervalWhen } from "rooks"
import { CurrentTime, EqualPlayback, IsLive, Playback, SetTime } from "../../api/Video"
import { Hour, IsLocalToday, Second, ToLongLocalDateTime, ToLongLocalTime, ToShortLocalTime } from "../../config/time"
import { darken } from "../../theme/Theme"
import { TenMinutesWindow } from "./TimelineChoice"

export interface TimelineChartProps {
    window: number
    step: number
    small: boolean
    playback: Playback
    onPlaybackChange: (playback: Playback) => void
}

const zeroPageStart = (window: number, now: number) => {
    if (window <= Hour) {
        return now - (now % window)
    }
    const timeInHour = now % Hour
    const hourInDay = new Date(now).getHours() * Hour
    return now - timeInHour - (hourInDay % window)
}

const pageStart = (page: number, window: number, now: number) => {
    return zeroPageStart(window, now) - page * window
}

const asPage = (playback: Playback, window: number, now: number) => {
    const time = CurrentTime(playback, now)
    const start = zeroPageStart(window, now)
    if (time >= start) {
        return 0
    }
    return Math.floor((start - time) / window) + 1
}

export function TimelineChart(props: TimelineChartProps) {
    const { step, window, small, playback, onPlaybackChange } = props

    const theme = useTheme()

    const [now, setNow] = useState(Date.now())
    const [page, setPage] = useState(0)
    const [value, setValue] = useState(0)
    const [dragging, setDragging] = useState(false)
    const [applied, setApplied] = useState(true)

    const min = 0
    const max = useMemo(() => window / step, [window, step])
    const splits = useMemo(() => {
        if (window === TenMinutesWindow.value) {
            return small ? 2 : 5
        }
        return small ? 3 : 6
    }, [window, small])

    const asTime = useCallback(
        (value: number) => {
            const start = pageStart(page, window, now)
            return start + value * step
        },
        [window, page, step, now]
    )

    const asValue = useCallback(
        (time: number) => {
            const start = pageStart(page, window, now)

            if (time <= start) {
                return 0
            }
            if (time >= start + window) {
                return window / step
            }
            return Math.floor((time - start) / step)
        },
        [window, page, step, now]
    )

    useEffect(() => {
        const value = asValue(CurrentTime(playback, now))
        setValue(value)
        const desiredPage = asPage(playback, window, now)
        if (desiredPage !== page) {
            setPage(desiredPage)
        }
    }, [playback, now, window, max, page, asValue])

    useIntervalWhen(
        () => {
            setNow(Date.now())
        },
        Second,
        true,
        true
    )

    useIntervalWhen(
        () => {
            if (dragging) {
                applyValue()
            }
        },
        Second,
        true,
        true
    )

    const asMark = useCallback(
        (idx: number) => {
            const value = (window / splits / step) * idx
            return {
                value: value,
                label: ToShortLocalTime(asTime(value)),
            }
        },
        [window, asTime, step, splits]
    )

    const renderLabel = (value: number) => {
        const time = asTime(value)
        const s = IsLocalToday(new Date(time)) ? ToLongLocalTime(time) : ToLongLocalDateTime(time)
        return (
            <Stack direction="row" alignItems="center" spacing={1}>
                {IsLive(playback) ? (
                    <PlayArrow fontSize="small" htmlColor={theme.palette.success.light} />
                ) : (
                    <History fontSize="small" htmlColor={theme.palette.warning.light} />
                )}
                <Typography variant="body2">{s}</Typography>
            </Stack>
        )
    }

    const onChangeTime = (value: number) => {
        const desired = SetTime(playback, asTime(value))
        if (!EqualPlayback(desired, playback, 2 * Second)) {
            onPlaybackChange(desired)
        }
    }
    const applyValue = () => {
        if (!applied) {
            setApplied(true)
            setNow(Date.now())
            onChangeTime(value)
        }
    }
    const proposeValue = (value: number) => {
        setValue(asValue(CurrentTime(SetTime(playback, asTime(value)))))
        setApplied(false)
    }
    const commitValue = (value: number) => {
        setApplied(true)
        setNow(Date.now())
        onChangeTime(value)
    }

    return (
        <Stack direction="row" spacing={0.5} alignItems="center" height="100%" width="100%">
            <Stack
                direction="row"
                width="100%"
                height="100%"
                alignItems="center"
                spacing={0.5}
                sx={{
                    backgroundImage: darken(0.1),
                    borderColor: theme.palette.panel.border,
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderRadius: "5px",
                    padding: "3px",
                }}
            >
                <Slider
                    size="small"
                    valueLabelFormat={renderLabel}
                    valueLabelDisplay="on"
                    value={value}
                    marks={Array.from({ length: splits + 1 }, (_, i) => asMark(i))}
                    min={min}
                    max={max}
                    onChange={(_, v) => {
                        proposeValue(v as number)
                    }}
                    onChangeCommitted={(_, v) => {
                        setDragging(false)
                        commitValue(v as number)
                    }}
                    onMouseDown={() => setDragging(true)}
                    onTouchStart={() => setDragging(true)}
                    slotProps={{
                        root: {
                            style: {
                                top: "-0.35em",
                                marginRight: "2em",
                                marginLeft: "2em",
                                marginBottom: "0px",
                                paddingTop: "0.75em",
                                paddingBottom: "0.75em",
                            },
                        },
                        markLabel: { style: { fontSize: "0.75em", top: "1.5em", color: theme.palette.text.secondary } },
                        thumb: { style: { zIndex: 30 } },
                    }}
                />
            </Stack>
        </Stack>
    )
}
