import L from "leaflet"
import { renderToString } from "react-dom/server"
import { staticURL } from "../../../config/urls"

export function targetIcon(label: string) {
    return labeledIcon(label, staticURL("marker-target.png"))
}

export function cameraIcon(label: string) {
    return labeledIcon(label, staticURL("marker-camera.png"))
}

function labeledIcon(label: string, icon: string) {
    return new L.DivIcon({
        className: "nonexistent-class",
        popupAnchor: [0, -33],
        html: renderToString(
            <>
                <img
                    alt="marker"
                    style={{ position: "absolute", left: "-6px", top: "-35px" }}
                    src={icon}
                    width="25"
                    height="41"
                />
                <img
                    alt="marker shadow"
                    style={{ position: "absolute", left: "0px", top: "-17px" }}
                    src={staticURL("marker-default-shadow.png")}
                    width="40"
                    height="25"
                />
                <span
                    style={{
                        position: "absolute",
                        left: "6px",
                        transform: "translateX(-50%)",
                        top: "6px",
                        alignItems: "center",
                        textAlign: "center",
                        textShadow: "2px 2px 2px black",
                        fontWeight: 900,
                        fontSize: "large",
                        WebkitTextFillColor: "gold",
                        WebkitTextStrokeWidth: "0.5px",
                        WebkitTextStrokeColor: "black",
                    }}
                >
                    {label}
                </span>
            </>
        ),
    })
}
