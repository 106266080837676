import { Button, Dialog, DialogActions, DialogTitle, Divider, List, ListItemButton, ListItemText } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Device } from "../api/Device"

export interface PickDeviceDialogProps {
    title: string
    devices: Device[]
    onPicked: (device: Device) => void
    onCancel: () => void
}

export function PickDeviceDialog(props: PickDeviceDialogProps) {
    const { onCancel, onPicked, devices, title } = props

    const { t } = useTranslation()

    return (
        <Dialog onClose={onCancel} aria-labelledby="pick-device-dialog-title" open={true}>
            <DialogTitle id="pick-device-dialog-title">{title}</DialogTitle>
            <Divider />
            <List dense>
                {devices.map((device) => (
                    <ListItemButton onClick={() => onPicked(device)} key={device.ID}>
                        <ListItemText primary={device.Name} />
                    </ListItemButton>
                ))}
            </List>
            <Divider />
            <DialogActions>
                <Button onClick={onCancel} variant="contained" color="primary">
                    {t("action.cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
