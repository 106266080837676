import { Box, Grid, Stack, Typography, useTheme } from "@mui/material"
import { useSnackbar } from "notistack"
import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Unit } from "../../api/Customer"
import { Item, Payload } from "../../api/OpenHAB"
import { OpenHABStream } from "../../api/OpenHABStream"
import { http } from "../../backend/request"
import { openHabEndpointURL } from "../../config/urls"
import { MultiChart } from "../common/MultiChart"
import { OneDayRange, RangeChoice } from "../common/RangeChoice"

export interface WeatherProps {
    unit: Unit
}

export function Weather(props: WeatherProps) {
    const { unit } = props

    const [items, setItems] = useState<Item[]>([])
    const [range, setRange] = useState(OneDayRange)

    const snackbar = useSnackbar()
    const theme = useTheme()
    const { t } = useTranslation()

    const relevantZones = useMemo(
        () =>
            new Set([
                "localCurrentConditionIcon",
                "localCurrentTemperature",
                "localCurrentApparentTemperature",
                "localCurrentWindSpeed",
                "localCurrentWindDirection",
                "localCurrentRainVolume",
                "localCurrentSnowVolume",
                "localCurrentVisibility",
                "localCurrentHumidity",
            ]),
        []
    )
    const isRelevant = useCallback((name: string) => relevantZones.has(name), [relevantZones])

    const reloadItems = useCallback(() => {
        http<Item[]>("Getting unit items", openHabEndpointURL(unit, `items`), snackbar)
            .then((result) =>
                setItems(result.filter((item) => isRelevant(item.name)).sort((a, b) => (a.name > b.name ? 1 : -1)))
            )
            .catch((e) => console.log(e))
    }, [isRelevant, snackbar, unit])

    const onOpenHABMessage = useCallback((name: string, payload: Payload) => {
        console.log("changing item", name, "to", payload.value)
        setItems((old) =>
            old.map((item) =>
                item.name === name
                    ? {
                          name: item.name,
                          groupNames: item.groupNames,
                          tags: item.tags,
                          label: item.label,
                          state: payload.value,
                      }
                    : item
            )
        )
    }, [])

    const onOpenHABConnected = useCallback(() => {
        console.log("stream connected")
        reloadItems()
    }, [reloadItems])

    const weatherIcon = useMemo(() => items.find((item) => item.name === "localCurrentConditionIcon"), [items])
    const temperature = useMemo(() => items.find((item) => item.name === "localCurrentTemperature"), [items])
    const apparentTemperature = useMemo(
        () => items.find((item) => item.name === "localCurrentApparentTemperature"),
        [items]
    )
    const windSpeed = useMemo(() => items.find((item) => item.name === "localCurrentWindSpeed"), [items])
    const windDirection = useMemo(() => items.find((item) => item.name === "localCurrentWindDirection"), [items])
    const rainVolume = useMemo(() => items.find((item) => item.name === "localCurrentRainVolume"), [items])
    const snowVolume = useMemo(() => items.find((item) => item.name === "localCurrentSnowVolume"), [items])
    const visibility = useMemo(() => items.find((item) => item.name === "localCurrentVisibility"), [items])
    const humidity = useMemo(() => items.find((item) => item.name === "localCurrentHumidity"), [items])

    const temperatureChannel = useMemo(
        () => [
            {
                name: "Temp1",
                label: `Temperature`,
                color: theme.palette.primary.main,
            },
        ],
        [theme]
    )

    const renderValue = useCallback(
        (label: string, item?: Item) =>
            item ? (
                <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Stack sx={{ backgroundColor: theme.palette.background.paper }} p={1}>
                        <Typography>{label}</Typography>
                        <Typography color={theme.palette.primary.main}>{item.state}</Typography>
                    </Stack>
                </Grid>
            ) : null,
        [theme]
    )

    return (
        <Stack sx={{ py: 2, px: 4, alignItems: "center", width: "100%" }}>
            <Box sx={{ width: "min(100%,1280px)" }}>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Box flexGrow={1}>
                        <OpenHABStream
                            unit={unit}
                            itemFilter={isRelevant}
                            onMessage={onOpenHABMessage}
                            onConnected={onOpenHABConnected}
                        />
                    </Box>
                    <RangeChoice value={range} onChange={setRange} />
                </Stack>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Stack direction="row" sx={{ alignItems: "center" }} spacing={2}>
                            <Typography variant="h5">{t("offering.weather")}</Typography>
                            {weatherIcon && (
                                <Grid item xs={12} sm={6} md={3} lg={2}>
                                    <img src={weatherIcon.state} alt={t("weather.pictogram")} />
                                </Grid>
                            )}
                        </Stack>
                    </Grid>
                    {renderValue(t("weather.temperature"), temperature)}
                    {renderValue(t("weather.feels_like"), apparentTemperature)}
                    {renderValue(t("weather.wind_speed"), windSpeed)}
                    {renderValue(t("weather.wind_direction"), windDirection)}
                    {renderValue(t("weather.rain_volume"), rainVolume)}
                    {renderValue(t("weather.humidity"), humidity)}
                    {renderValue(t("weather.snow_volume"), snowVolume)}
                    {renderValue(t("weather.visibility"), visibility)}
                    <Grid item xs={12}>
                        <Typography variant="h6">{t("weather.temperature_history")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                backgroundColor: theme.palette.background.paper,
                                width: "100%",
                                height: {
                                    xs: "150px",
                                    sm: "200px",
                                    md: "250px",
                                    lg: "300px",
                                },
                            }}
                            p={1}
                        >
                            <MultiChart
                                unit={unit}
                                items={temperatureChannel}
                                millis={range.millis}
                                valueUnit="°C"
                                startValue={0}
                                rulers={[
                                    {
                                        label: "0 °C",
                                        value: 0,
                                        color: "red",
                                    },
                                ]}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Stack>
    )
}
