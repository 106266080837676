import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { request } from "../../config/headers"
import { endpointURL } from "../../config/urls"
import {
    CreateCustomerPermissionRequest,
    ListCustomerPermissionsResponse,
    ListOwnPermissionsResponse,
    Permission,
    UpdateCustomerPermissionRequest,
} from "../Authz"

interface customerPermission {
    customerID: number
    permissionID: number
}

interface customerPermissionRole {
    customerID: number
    permissionID: number
    roleID: number
}

interface customerPermissionActor {
    customerID: number
    permissionID: number
    actorID: number
}

interface updateCustomerPermission {
    customerID: number
    request: UpdateCustomerPermissionRequest
}

export const permissionApi = createApi({
    reducerPath: "permissionApi",
    baseQuery: fetchBaseQuery({ ...request, baseUrl: endpointURL("") }),
    tagTypes: ["Permissions"],
    endpoints: (builder) => ({
        getCustomerPermissions: builder.query<Permission[], number>({
            query: (customerID) => `customers/${customerID}/permissions`,
            transformResponse: (response: ListCustomerPermissionsResponse) => response.data || [],
            providesTags: [{ type: "Permissions", id: "LIST" }],
        }),
        getOwnPermissions: builder.query<ListOwnPermissionsResponse, void>({
            query: () => `self/permissions`,
            providesTags: [{ type: "Permissions", id: "LIST" }],
        }),
        createCustomerPermission: builder.mutation<void, CreateCustomerPermissionRequest>({
            query: (args) => ({
                url: `customers/${args.customer_id}/permissions`,
                body: args,
                method: "POST",
            }),
            invalidatesTags: [{ type: "Permissions", id: "LIST" }],
        }),
        deleteCustomerPermission: builder.mutation<void, customerPermission>({
            query: (args) => ({
                url: `customers/${args.customerID}/permissions/${args.permissionID}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "Permissions", id: "LIST" }],
        }),
        updateCustomerPermission: builder.mutation<void, updateCustomerPermission>({
            query: (args) => ({
                url: `customers/${args.customerID}/permissions/${args.request.id}`,
                method: "PUT",
                body: args.request,
            }),
            invalidatesTags: [{ type: "Permissions", id: "LIST" }],
        }),
        addCustomerPermissionBaseRole: builder.mutation<void, customerPermissionRole>({
            query: (args) => ({
                url: `customers/${args.customerID}/permissions/${args.permissionID}/base-roles/${args.roleID}`,
                method: "PUT",
            }),
            invalidatesTags: [{ type: "Permissions", id: "LIST" }],
        }),
        removeCustomerPermissionBaseRole: builder.mutation<void, customerPermissionRole>({
            query: (args) => ({
                url: `customers/${args.customerID}/permissions/${args.permissionID}/base-roles/${args.roleID}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "Permissions", id: "LIST" }],
        }),
        addCustomerPermissionCommonRole: builder.mutation<void, customerPermissionRole>({
            query: (args) => ({
                url: `customers/${args.customerID}/permissions/${args.permissionID}/common-roles/${args.roleID}`,
                method: "PUT",
            }),
            invalidatesTags: [{ type: "Permissions", id: "LIST" }],
        }),
        removeCustomerPermissionCommonRole: builder.mutation<void, customerPermissionRole>({
            query: (args) => ({
                url: `customers/${args.customerID}/permissions/${args.permissionID}/common-roles/${args.roleID}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "Permissions", id: "LIST" }],
        }),
        addCustomerPermissionUser: builder.mutation<void, customerPermissionActor>({
            query: (args) => ({
                url: `customers/${args.customerID}/permissions/${args.permissionID}/users/${args.actorID}`,
                method: "PUT",
            }),
            invalidatesTags: [{ type: "Permissions", id: "LIST" }],
        }),
        removeCustomerPermissionUser: builder.mutation<void, customerPermissionActor>({
            query: (args) => ({
                url: `customers/${args.customerID}/permissions/${args.permissionID}/users/${args.actorID}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "Permissions", id: "LIST" }],
        }),
        addCustomerPermissionGroup: builder.mutation<void, customerPermissionActor>({
            query: (args) => ({
                url: `customers/${args.customerID}/permissions/${args.permissionID}/groups/${args.actorID}`,
                method: "PUT",
            }),
            invalidatesTags: [{ type: "Permissions", id: "LIST" }],
        }),
        removeCustomerPermissionGroup: builder.mutation<void, customerPermissionActor>({
            query: (args) => ({
                url: `customers/${args.customerID}/permissions/${args.permissionID}/groups/${args.actorID}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "Permissions", id: "LIST" }],
        }),
    }),
})

export const {
    useGetCustomerPermissionsQuery,
    useLazyGetOwnPermissionsQuery,
    useCreateCustomerPermissionMutation,
    useDeleteCustomerPermissionMutation,
    useUpdateCustomerPermissionMutation,
    useAddCustomerPermissionBaseRoleMutation,
    useRemoveCustomerPermissionBaseRoleMutation,
    useAddCustomerPermissionCommonRoleMutation,
    useRemoveCustomerPermissionCommonRoleMutation,
    useAddCustomerPermissionUserMutation,
    useRemoveCustomerPermissionUserMutation,
    useAddCustomerPermissionGroupMutation,
    useRemoveCustomerPermissionGroupMutation,
} = permissionApi
