import { Modal, IconButton, Box, useTheme } from "@mui/material"
import CancelIcon from "@mui/icons-material/Cancel"
import { useTranslation } from "react-i18next"
import { ReactNode, useState } from "react"

export interface ImageModalProps {
    open: boolean
    handleClose: () => void
    imageUrl: string
    children?: ReactNode
}

export default function ImageModal(props: ImageModalProps) {
    const { open, handleClose, imageUrl, children } = props
    const { t } = useTranslation()
    const theme = useTheme()
    const [aspectRatio, setAspectRatio] = useState(16 / 9)

    const handleImageLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const target = event.target as HTMLImageElement
        const width = target.naturalWidth
        const height = target.naturalHeight
        setAspectRatio(width / height)
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "15px",
            }}
        >
            <Box
                width={`min(${theme.breakpoints.values.xl}px, min(calc(100vw - 64px), calc(100vh - 80px) * ${aspectRatio}))`}
                sx={{
                    position: "relative",
                    outline: "none",
                }}
            >
                <IconButton
                    color="primary"
                    sx={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                    }}
                    onClick={handleClose}
                >
                    <CancelIcon />
                </IconButton>
                <img
                    src={imageUrl}
                    alt={t("dialog.snapshot.snapshot")}
                    style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                    }}
                    onLoad={handleImageLoad}
                    onClick={handleClose}
                />
                {children}
            </Box>
        </Modal>
    )
}
