import { Box, Button, Tooltip } from "@mui/material"
import { ReactElement } from "react"

export interface PTZButtonProps {
    disabled?: boolean
    tooltip: string
    icon: ReactElement
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning"
    onClick?: () => void
    onMouseDown?: () => void
    onMouseUp?: () => void
}

export function PTZButton(props: PTZButtonProps) {
    const { disabled, tooltip, icon, onClick, onMouseDown, onMouseUp, color } = props

    return (
        <Tooltip title={tooltip} disableInteractive>
            <Box>
                <Button
                    variant="outlined"
                    sx={{ padding: "1px", margin: "0px", minWidth: "0px" }}
                    disabled={disabled}
                    onClick={onClick}
                    onMouseDown={onMouseDown}
                    onMouseUp={onMouseUp}
                    onTouchStart={onMouseDown}
                    onTouchEnd={onMouseUp}
                    color={color}
                >
                    {icon}
                </Button>
            </Box>
        </Tooltip>
    )
}
