import { Delete, Edit } from "@mui/icons-material"
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { ImageOverlay } from "../api/ImageOverlay"
import { ActionButton } from "./ActionButton"

export interface ImageOverlaysDialogProps {
    open: boolean
    admin: boolean
    imageOverlays: ImageOverlay[]
    onClose: () => void
    onDelete: (imageOverlay: ImageOverlay) => void
    onEdit: (imageOverlay: ImageOverlay) => void
    onCreate: () => void
}

export function ImageOverlaysDialog(props: ImageOverlaysDialogProps) {
    const { open, admin, imageOverlays, onClose, onDelete, onEdit, onCreate } = props

    const { t } = useTranslation()

    const imageOverlayRow = (imageOverlay: ImageOverlay) => {
        return (
            <TableRow key={imageOverlay.ID}>
                <TableCell component="th" scope="row">
                    {imageOverlay.Name}
                </TableCell>
                <TableCell align="right">{imageOverlay.ID}</TableCell>
                <TableCell align="right">
                    <a href={imageOverlay.URL}>{imageOverlay.URL}</a>
                </TableCell>
                <TableCell align="right">
                    <Stack direction="row" spacing={0}>
                        <Box flexGrow={1} />
                        <ActionButton
                            tooltip={t("action.edit_image_overlay_tooltip")}
                            icon={<Edit />}
                            color="primary"
                            disabled={!admin}
                            onClick={() => onEdit(imageOverlay)}
                        />
                        <ActionButton
                            tooltip={t("action.delete_image_overlay_tooltip")}
                            icon={<Delete />}
                            color="secondary"
                            disabled={!admin}
                            onClick={() => onDelete(imageOverlay)}
                        />
                    </Stack>
                </TableCell>
            </TableRow>
        )
    }

    return (
        <Dialog onClose={onClose} aria-labelledby="image-overlays-dialog-title" maxWidth="lg" fullWidth open={open}>
            <DialogTitle id="image-overlays-dialog-title">{t("tracking.overlays")}</DialogTitle>
            <TableContainer component="div" style={{ overflow: "auto", paddingBottom: 5, maxHeight: "75%" }}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("field.name")}</TableCell>
                            <TableCell align="right">{t("field.id")}</TableCell>
                            <TableCell align="right">{t("field.url")}</TableCell>
                            <TableCell align="right">{t("field.actions")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{imageOverlays.map(imageOverlayRow)}</TableBody>
                </Table>
            </TableContainer>
            <DialogActions>
                <Button onClick={onCreate} variant="contained" color="primary">
                    {t("action.new_overlay")}
                </Button>
                <Button onClick={onClose} variant="contained" color="primary">
                    {t("action.close")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
