import { useCallback, useEffect, useState } from "react";
import { hookTimeout } from "../config/time";

export function useDelayed(startActive: boolean, delay: number) {
    const [active, setActive] = useState(startActive)
    const [triggered, setTriggered] = useState(false)

    const trigger = useCallback(() => setTriggered(true), [])
    const done = useCallback(() => {
        setTriggered(false)
        setActive(false)
    }, [])

    useEffect(() => {
        if (!triggered) {
            return
        }
        return hookTimeout(() => {
            setTriggered(false)
            setActive(true)
        }, delay)
    }, [triggered, delay])

    return { active, trigger, done }
}
