import { CircularProgress, Stack, TableCell, TableRow, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

export const LoadingRow = () => {
    const { t } = useTranslation()

    return (
        <TableRow>
            <TableCell colSpan={99999}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <CircularProgress thickness={5} size={24} />
                    <Typography variant="body2" fontStyle="italic">
                        {t("message.loading")}
                    </Typography>
                </Stack>
            </TableCell>
        </TableRow>
    )
}
