import { CalendarMonth } from "@mui/icons-material"
import { Button, Stack } from "@mui/material"
import { DateTimeField } from "@mui/x-date-pickers"
import { DateTime } from "luxon"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { AddDuration, Hour, Minute } from "../../config/time"
import { DateTimePickerDialog } from "./DateTimePickerDialog"

export interface TimeChoiceProps {
    value: Date
    label: string
    parent?: Element
    onChange: (value: Date) => void
}

const buttons = [
    {
        label: "-1h",
        difference: -Hour,
    },
    {
        label: "-10m",
        difference: -10 * Minute,
    },
    {
        label: "-1m",
        difference: -1 * Minute,
    },
    {
        label: "+1m",
        difference: 1 * Minute,
    },
    {
        label: "+10m",
        difference: 10 * Minute,
    },
    {
        label: "+1h",
        difference: Hour,
    },
]

export const TimeChoice = (props: TimeChoiceProps) => {
    const { value, label, parent, onChange } = props
    const { t } = useTranslation()
    const [pickingDate, setPickingDate] = useState(false)

    return (
        <Stack>
            <Stack direction="row">
                <DateTimeField
                    label={t(label)}
                    value={DateTime.fromJSDate(value)}
                    onChange={(v) => {
                        v && onChange(v.toJSDate())
                    }}
                    sx={{ width: "100%" }}
                    formatDensity="dense"
                />
            </Stack>
            <Stack direction="row" spacing={0.5}>
                <Button
                    variant="outlined"
                    size="small"
                    sx={{ minWidth: "32px", flexGrow: 1 }}
                    onClick={() => setPickingDate(true)}
                >
                    <CalendarMonth />
                </Button>
                {buttons.map((r) => (
                    <Button
                        key={"diff-button-" + r.label}
                        sx={{ minWidth: "32px", flexGrow: 1 }}
                        variant="outlined"
                        size="small"
                        onClick={() => onChange(AddDuration(value, r.difference))}
                    >
                        {r.label}
                    </Button>
                ))}
            </Stack>
            {pickingDate && (
                <DateTimePickerDialog
                    initialValue={value}
                    onClose={() => setPickingDate(false)}
                    onPicked={(picked) => {
                        setPickingDate(false)
                        onChange(picked)
                    }}
                    parent={parent}
                />
            )}
        </Stack>
    )
}
