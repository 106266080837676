export interface CustomerRole {
    UserID: number
    CustomerID: number
    Role: string
    Expiration?: Date
}

export const AdminRole = "admin"

export const GlobalAdminID = 1

export const isGlobalAdmin = (userID: number) => userID === GlobalAdminID
