import { Path } from "react-router-dom"
import { Unit } from "../api/Customer"

export const apiURL = (() => {
    const url = process.env.REACT_APP_API_URL_OVERRIDE || process.env.PUBLIC_URL
    console.log("Serving API from: ", url)
    return url
})()

export const oryEndpoint = process.env.REACT_APP_ORY_API_URL_OVERRIDE
    ? process.env.REACT_APP_ORY_API_URL_OVERRIDE
    : `${process.env.PUBLIC_URL}/..`

export const staticURL = (staticPath: string) => `${process.env.PUBLIC_URL}/${staticPath}`
export const unitURL = (unit: Unit) => `${process.env.PUBLIC_URL}/../units/${unit.ShortName}`
export const endpointURL = (endpoint: string) => `${apiURL}/api/${endpoint}`

export const eyeTrackEndpointURL = (unit: Unit, endpoint: string) => {
    const base = process.env.REACT_APP_EYETRACK_API_URL_OVERRIDE || `${apiURL}/api/eyetrack/units/${unit.ID}`
    return `${base}/${endpoint}`
}

export const eyeCastEndpointURL = (unit: Unit, cameraID: number, region: string) => {
    const base = process.env.REACT_APP_EYECAST_API_URL_OVERRIDE || `${process.env.PUBLIC_URL}/../eyecast`
    return `${base}/api/sites/${unit.SiteID}/units/${unit.ShortName}/cameras/${cameraID}?region=${region}`
}

export const openHabEndpointURL = (unit: Unit, endpoint: string) => {
    const base = process.env.REACT_APP_OPENHAB_API_URL_OVERRIDE || unitURL(unit)
    return `${base}/rest/${endpoint}`
}

export const monoEndpointURL = (endpoint: string) => {
    const base = process.env.REACT_APP_MONO_API_URL_OVERRIDE || `${process.env.PUBLIC_URL}/..`
    return `${base}/api/v1/${endpoint}`
}

export const snapshotEndpointURL = (endpoint: string) => {
    const base = process.env.REACT_APP_SNAPSHOT_API_URL_OVERRIDE || `${process.env.PUBLIC_URL}/../snapshot`
    return `${base}/${endpoint}`
}

export const urlTo = (path: string): Partial<Path> => {
    const parsed = new URL(path, "http://dummy")

    return {
        pathname: parsed.pathname,
        search: parsed.search,
        hash: parsed.hash,
    }
}

export const retainConfig = (to: Partial<Path>) => {
    const params = new URLSearchParams(window.location.search)
    const orig = new URLSearchParams(to.search)
    const result = new URLSearchParams()
    params.forEach((v, k) => {
        if (k === "mode" || k === "embedded") {
            result.set(k, v)
        }
    })
    orig.forEach((v, k) => result.set(k, v))
    return {
        ...to,
        search: result.toString(),
    }
}
