export const errorMessage = (error: any) => {
    var status = ""
    var msg = ""

    console.debug("ERROR", error)

    if (error && "status" in error) {
        status = error.status
    }
    if (error && "data" in error) {
        if ("Message" in error.data) {
            msg = error.data.Message
        } else {
            msg = error.data
        }
    }
    if (error && "error" in error) {
        msg = error.error
    }

    if (status && msg) {
        return `${status}: ${msg}`
    }
    if (status) {
        return status
    }
    if (msg) {
        return msg
    }
    return "unknown"
}
