import { People, Person, QuestionMark } from "@mui/icons-material"
import { useTheme } from "@mui/material"
import { Actor, ActorType } from "../../api/Authz"

export interface ActorIconProps {
    actor: Actor
}

export const ActorIcon = (props: ActorIconProps) => {
    const { actor } = props
    const theme = useTheme()
    const htmlColor = theme.palette.text.secondary

    switch (actor.type) {
        case ActorType.USER:
            return <Person fontSize="small" htmlColor={htmlColor} />
        case ActorType.GROUP:
            return <People fontSize="small" htmlColor={htmlColor} />
        default:
            return <QuestionMark fontSize="small" htmlColor={htmlColor} />
    }
}
