import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { request } from "../../config/headers"
import { endpointURL } from "../../config/urls"
import { Group, ListCustomerGroupResponse } from "../Group"

export const groupApi = createApi({
    reducerPath: "groupApi",
    baseQuery: fetchBaseQuery({ ...request, baseUrl: endpointURL("") }),
    tagTypes: ["Groups"],
    endpoints: (builder) => ({
        getCustomerGroups: builder.query<Group[], number>({
            query: (customerID) => `customers/${customerID}/groups`,
            transformResponse: (response: ListCustomerGroupResponse) => response.data || [],
            providesTags: [{ type: "Groups", id: "LIST" }],
        }),
    }),
})

export const { useGetCustomerGroupsQuery } = groupApi
