import { FlashlightOff, FlashlightOn } from "@mui/icons-material"
import { Grid, Stack, Switch, Tooltip, Typography, useTheme } from "@mui/material"
import { t } from "i18next"
import { emphasized } from "../../theme/Theme"

export interface LightInfoProps {
    id: number
    displayName?: string
    value?: boolean
    allowed: boolean
    flipSwitch: (on: boolean) => void
}

export const LightInfo = (props: LightInfoProps) => {
    const { id, displayName, value, allowed, flipSwitch } = props
    const theme = useTheme()

    const label = displayName || t("unit.light_display_name", { id: id })
    const tooltip = `${t("unit.light_display_name", { id: id })}${!displayName ? "" : ": " + displayName}.`

    return (
        <Grid item xs={12} sm={6} md={4} lg={3}>
            <Stack
                direction="row"
                px={1}
                height="100%"
                sx={{ alignItems: "center", backgroundColor: theme.palette.background.paper }}
            >
                <Stack
                    direction="row"
                    sx={{
                        alignItems: "center",
                        flexGrow: 1,
                        overflow: "hidden",
                    }}
                >
                    {value !== undefined && (
                        <Tooltip
                            title={value ? t("action.switch_off_tooltip") : t("action.switch_on_tooltip")}
                            disableInteractive
                        >
                            <span>
                                <Switch disabled={!allowed} checked={value} onClick={() => flipSwitch(value)} />
                            </span>
                        </Tooltip>
                    )}
                    <Tooltip title={tooltip} disableInteractive>
                        <Typography
                            sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {label}
                        </Typography>
                    </Tooltip>
                </Stack>
                {value ? (
                    <FlashlightOn htmlColor={theme.palette.warning.main} />
                ) : (
                    <FlashlightOff htmlColor={emphasized(0.2, theme)} />
                )}
            </Stack>
        </Grid>
    )
}
