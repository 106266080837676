import { Layers } from "@mui/icons-material"
import { IconButton, Paper, Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next"
import { disablePropagation } from "./Propagation"

export interface MUILayersProps {
    onRotate: () => void
}

export const MUILayers = (props: MUILayersProps) => {
    const { onRotate } = props

    const { t } = useTranslation()

    return (
        <div className="leaflet-top leaflet-right" ref={disablePropagation}>
            <div className="leaflet-bar leaflet-control">
                <Paper>
                    <IconButton onClick={onRotate}>
                        <Tooltip title={t("action.switch_layers_tooltip")} disableInteractive>
                            <Layers />
                        </Tooltip>
                    </IconButton>
                </Paper>
            </div>
        </div>
    )
}
