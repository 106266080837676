export interface Paging {
    PageSize: number
    Pinned: number[]
    Page: number
    Pages: number
    Count: number
}

export const WithPageSize = (paging: Paging, pageSize: number): Paging => {
    const actualSize = pageSize - paging.Pinned.length
    if (actualSize <= 0) {
        return {
            PageSize: pageSize,
            Page: 0,
            Pages: 0,
            Pinned: paging.Pinned,
            Count: paging.Count,
        }
    }
    const oldStartIdx = FirstNonPinnedIndex(paging)
    const pinnedBefore = paging.Pinned.filter((i) => i <= oldStartIdx).length
    return {
        PageSize: pageSize,
        Page: Math.floor((oldStartIdx - pinnedBefore) / actualSize),
        Pages: Math.ceil((paging.Count - paging.Pinned.length) / actualSize),
        Pinned: paging.Pinned,
        Count: paging.Count,
    }
}

export const WithPage = (paging: Paging, page: number): Paging => {
    page = Math.max(0, Math.min(paging.Pages - 1, page))
    return {
        PageSize: paging.PageSize,
        Page: page,
        Pages: paging.Pages,
        Pinned: paging.Pinned,
        Count: paging.Count,
    }
}

export const WithPinned = (paging: Paging, pinned: number[]): Paging => {
    const actualSize = paging.PageSize - pinned.length
    if (actualSize <= 0) {
        return {
            PageSize: paging.PageSize,
            Page: 0,
            Pages: 0,
            Pinned: pinned,
            Count: paging.Count,
        }
    }
    const oldStartIdx = FirstNonPinnedIndex(paging)
    const pinnedBefore = pinned.filter((i) => i <= oldStartIdx).length
    return {
        PageSize: paging.PageSize,
        Page: Math.floor((oldStartIdx - pinnedBefore) / actualSize),
        Pages: Math.ceil((paging.Count - pinned.length) / actualSize),
        Pinned: pinned,
        Count: paging.Count,
    }
}

export const FirstNonPinnedIndex = (paging: Paging) => {
    const actualSize = paging.PageSize - paging.Pinned.length
    if (actualSize <= 0) {
        return 0
    }
    const withoutPinning = paging.Page * actualSize
    let result = withoutPinning
    while (true) {
        const current = result
        result = paging.Pinned.filter((i) => i <= current).length + withoutPinning
        if (result === current) {
            return result
        }
    }
}

export const ActualCameraIndex = (paging: Paging, i: number) => {
    if (i < paging.Pinned.length) {
        return paging.Pinned[i]
    }
    const actualSize = paging.PageSize - paging.Pinned.length
    const withoutPinning = paging.Page * actualSize + i - paging.Pinned.length
    let result = withoutPinning
    while (true) {
        const current = result
        result = paging.Pinned.filter((i) => i <= current).length + withoutPinning
        if (result === current) {
            return result
        }
    }
}

export const EqualPaging = (p1: Paging, p2: Paging) => {
    if (
        p1.Count !== p2.Count ||
        p1.Page !== p2.Page ||
        p1.PageSize !== p2.PageSize ||
        p1.Pages !== p2.Pages ||
        p1.Pinned.length !== p2.Pinned.length
    ) {
        return false
    }
    return p1.Pinned.every((v, i) => p2.Pinned[i] === v)
}

export const SafePaging = (
    pageSize: number,
    recommendedPageSize: number,
    allowedSizes: number[],
    page: number,
    count: number,
    pinned: number[]
): Paging => {
    pageSize = SanitizePageSize(pageSize, recommendedPageSize, allowedSizes, count)
    pinned = SanitizePinned(pinned, count)
    const actualSize = pageSize - pinned.length

    if (actualSize <= 0) {
        return {
            PageSize: pageSize,
            Page: 0,
            Pages: 0,
            Pinned: pinned,
            Count: count,
        }
    }
    const pages = Math.ceil((count - pinned.length) / actualSize)
    page = Math.max(0, Math.min(pages - 1, page))
    return {
        PageSize: pageSize,
        Page: page,
        Pages: pages,
        Pinned: pinned,
        Count: count,
    }
}

export const SanitizePinned = (pinned: number[], count: number) => {
    return pinned.filter(
        (v, i) => v >= 0 && v < count && v === Math.floor(v) && pinned.slice(0, i).every((b) => b !== v)
    )
}

export const DefaultPageSize = (recommendedPageSize: number, allowedSizes: number[], count: number) => {
    const bestFit = allowedSizes.find((v) => v >= count) || allowedSizes.at(-1) || recommendedPageSize
    return Math.min(recommendedPageSize, bestFit)
}

export const SanitizePageSize = (
    pageSize: number,
    recommendedPageSize: number,
    allowedSizes: number[],
    count: number
) => {
    if (pageSize === 0) {
        // Unspecified pageSize, let's go to the default.
        return DefaultPageSize(recommendedPageSize, allowedSizes, count)
    }
    // First equal or bigger size or the default.
    return (
        allowedSizes.find((v) => v >= pageSize) ||
        allowedSizes.at(-1) ||
        DefaultPageSize(recommendedPageSize, allowedSizes, count)
    )
}
