import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Device } from "../api/Device"

export interface DeviceDialogProps {
    defaultID?: number
    device?: Device
    onSave: (device: Device) => void
    onCancel: () => void
}

export function DeviceDialog(props: DeviceDialogProps) {
    const { defaultID, device, onSave, onCancel } = props
    const [id, setID] = useState(device?.ID || defaultID || 1)
    const [idError, setIDError] = useState("")
    const [name, setName] = useState(device?.Name)
    const [nameError, setNameError] = useState("")
    const [imei, setIMEI] = useState(device?.IMEI)
    const [imeiError, setIMEIError] = useState("")

    const { t } = useTranslation()

    const onConfirm = () => {
        if (hasError()) {
            return
        }
        onSave({
            ID: id || defaultID || 1,
            Name: name || "",
            IMEI: imei || "",
        })
    }

    const hasError = () => !checkID(id) || !checkName(name) || !checkIMEI(imei)

    const setIDChecked = (id: number) => {
        setID(id)
        checkID(id)
    }

    const checkID = (id?: number) => {
        if (!id) {
            setIDError(t("message.required_field"))
            return false
        }
        if (id <= 0 || id > 64) {
            setIDError(t("message.has_to_be_between", { min: 1, max: 64 }))
            return false
        }
        setIDError("")
        return true
    }

    const setNameChecked = (name: string) => {
        setName(name)
        checkName(name)
    }

    const checkName = (name?: string) => {
        if (!name) {
            setNameError(t("message.required_field"))
            return false
        }
        setNameError("")
        return true
    }

    const setIMEIChecked = (imei: string) => {
        setIMEI(imei)
        checkIMEI(imei)
    }

    const checkIMEI = (imei?: string) => {
        if (!imei) {
            setIMEIError(t("message.required_field"))
            return false
        }
        setIMEIError("")
        return true
    }

    return (
        <Dialog onClose={onCancel} aria-labelledby="device-dialog-title" maxWidth="xs" open={true}>
            <DialogTitle id="device-dialog-title">{t(device ? "action.edit_target" : "action.new_target")}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            id={t("field.id")}
                            label={t("field.id", { min: 1, max: 64 })}
                            defaultValue={device?.ID || defaultID || 1}
                            error={!!idError}
                            helperText={idError}
                            onChange={(event) => setIDChecked(+event.target.value)}
                            type="number"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                                min: 1,
                                max: 64,
                            }}
                            fullWidth={true}
                            disabled={!!device}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id={t("field.name")}
                            label={t("field.name")}
                            defaultValue={device?.Name || ""}
                            error={!!nameError}
                            helperText={nameError}
                            onChange={(event) => setNameChecked(event.target.value)}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id={t("field.imei")}
                            label={t("field.imei")}
                            defaultValue={device?.IMEI || ""}
                            error={!!imeiError}
                            helperText={imeiError}
                            onChange={(event) => setIMEIChecked(event.target.value)}
                            fullWidth={true}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onConfirm} variant="contained" color="primary">
                    {t(device ? "action.save" : "action.create")}
                </Button>
                <Button onClick={onCancel} variant="contained" color="primary">
                    {t("action.cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
