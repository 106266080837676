import { FlashlightOff, FlashlightOn } from "@mui/icons-material"
import { FormControlLabel, Stack, Switch, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Item, StateON } from "../../api/OpenHAB"
import { emphasized } from "../../theme/Theme"

export interface LightSwitchProps {
    light: Item
    disabled: boolean
    flipSwitch: (item: Item) => void
}

export const LightSwitch = (props: LightSwitchProps) => {
    const { light, disabled, flipSwitch } = props
    const theme = useTheme()
    const { t } = useTranslation()

    return (
        <Stack direction="row" px={1} sx={{ alignItems: "center", backgroundColor: theme.palette.background.paper }}>
            <FormControlLabel
                key="light-switch"
                disabled={disabled}
                control={<Switch checked={light.state === StateON} onClick={() => flipSwitch(light)} />}
                label={t("unit.floodlight")}
            />
            {light.state === StateON ? (
                <FlashlightOn key="light-switch-icon" htmlColor={theme.palette.warning.main} />
            ) : (
                <FlashlightOff key="light-switch-icon" htmlColor={emphasized(0.2, theme)} />
            )}
        </Stack>
    )
}
