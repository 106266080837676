import { Button, Card, CardContent } from "@mui/material"
import { useTranslation } from "react-i18next"
import { disablePropagation } from "../map/Propagation"

export interface CancelMenuProps {
    open: boolean
    onCancel: () => void
}

export function CancelMenu(props: CancelMenuProps) {
    const { open, onCancel } = props

    const { t } = useTranslation()

    return (
        <>
            {open && (
                <div className="leaflet-bottom leaflet-left" ref={disablePropagation}>
                    <div className="leaflet-bar leaflet-control">
                        <Card>
                            <CardContent>
                                <Button color="secondary" variant="contained" onClick={onCancel}>
                                    {t("action.cancel")}
                                </Button>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            )}
        </>
    )
}
