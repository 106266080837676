import { Button } from "@mui/material"
import { ReactElement } from "react"

export interface SettingsButtonProps {
    label: string
    disabled?: boolean
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning"
    icon: ReactElement
    onClick: () => void
}

export function SettingsButton(props: SettingsButtonProps) {
    const { label, disabled, color, icon, onClick } = props

    return (
        <Button
            variant="outlined"
            size="small"
            startIcon={icon}
            disabled={disabled}
            onClick={onClick}
            color={color}
            fullWidth
            sx={{
                padding: "0px 3px 0px 3px",
                fontSize: "0.7em",
            }}
        >
            {label}
        </Button>
    )
}
