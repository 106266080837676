import { Add, CenterFocusStrong, Clear, PlayArrow, PowerSettingsNew, Save } from "@mui/icons-material"
import { Divider, Typography, useTheme } from "@mui/material"
import { Stack } from "@mui/system"
import { useTranslation } from "react-i18next"
import { Operation } from "../../api/Authz"
import { Unit } from "../../api/Customer"
import { useCameraPermission } from "../../auth/AuthorizerProvider"
import { SettingsButton } from "./SettingsButton"

export interface SettingsControlsProps {
    unit: Unit
    cameraID: number
    allowMove: boolean
    hasHome: boolean
    hasPresets: boolean
    hasTargets: boolean
    hasCommands: boolean
    onAimHome: () => void
    onSaveHome: () => void
    onAimPreset: () => void
    onSavePreset: () => void
    onClearPreset: () => void
    onAddPreset: () => void
    onAimTarget: () => void
    onExecuteCommand: () => void
    onReboot: () => void
}

export function SettingsControls(props: SettingsControlsProps) {
    const {
        unit,
        cameraID,
        allowMove,
        hasHome,
        hasPresets,
        hasTargets,
        hasCommands,
        onAimHome,
        onSaveHome,
        onAimPreset,
        onSavePreset,
        onClearPreset,
        onAddPreset,
        onAimTarget,
        onExecuteCommand,
        onReboot,
    } = props

    const { t } = useTranslation()
    const theme = useTheme()

    const allowUpdateHome = useCameraPermission(Operation.UPDATE_CAMERA_HOME, unit, cameraID)
    const allowUpdatePreset = useCameraPermission(Operation.UPDATE_CAMERA_PRESET, unit, cameraID)
    const allowCreatePreset = useCameraPermission(Operation.CREATE_CAMERA_PRESET, unit, cameraID)
    const allowDeletePreset = useCameraPermission(Operation.DELETE_CAMERA_PRESET, unit, cameraID)
    const allowExecuteCommand = useCameraPermission(Operation.EXECUTE_CAMERA_COMMAND, unit, cameraID)
    const allowReboot = useCameraPermission(Operation.REBOOT_CAMERA, unit, cameraID)

    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            p={1}
            spacing={0.7}
            maxWidth="calc(100% - 6px)"
            zIndex={30}
            position="absolute"
            bottom={3}
            left={3}
            overflow="auto"
            sx={{
                backgroundColor: theme.palette.panel.background,
            }}
        >
            <Stack spacing={0.5}>
                <Typography fontSize="small">{t("camera.home")}:</Typography>
                <SettingsButton
                    label={t("action.aim")}
                    icon={<CenterFocusStrong />}
                    disabled={!allowMove || !hasHome}
                    onClick={onAimHome}
                />
                <SettingsButton
                    label={t("action.save")}
                    icon={<Save />}
                    disabled={!allowUpdateHome || !hasHome}
                    onClick={onSaveHome}
                />
            </Stack>
            <Divider orientation="vertical" flexItem />
            <Stack spacing={0.5}>
                <Typography fontSize="small">{t("camera.presets")}:</Typography>
                <SettingsButton
                    label={t("action.aim")}
                    icon={<CenterFocusStrong />}
                    disabled={!allowMove || !hasPresets}
                    onClick={onAimPreset}
                />
                <SettingsButton
                    label={t("action.save")}
                    icon={<Save />}
                    disabled={!allowUpdatePreset || !hasPresets}
                    onClick={onSavePreset}
                />
            </Stack>
            <Stack spacing={0.5}>
                <Typography fontSize="small">&nbsp;</Typography>
                <SettingsButton
                    label={t("action.add")}
                    icon={<Add />}
                    disabled={!allowCreatePreset}
                    onClick={onAddPreset}
                />
                <SettingsButton
                    label={t("action.clear")}
                    icon={<Clear />}
                    disabled={!allowDeletePreset || !hasPresets}
                    onClick={onClearPreset}
                />
            </Stack>
            <Divider orientation="vertical" flexItem />
            <Stack spacing={0.5}>
                <Typography fontSize="small">{t("camera.commands")}:</Typography>
                <SettingsButton
                    label={t("action.execute")}
                    icon={<PlayArrow />}
                    disabled={!allowExecuteCommand || !hasCommands}
                    onClick={onExecuteCommand}
                />
                <SettingsButton
                    label={t("action.reboot")}
                    icon={<PowerSettingsNew />}
                    disabled={!allowReboot}
                    onClick={onReboot}
                />
            </Stack>
            <Divider orientation="vertical" flexItem />
            <Stack spacing={0.5}>
                <Typography fontSize="small">{t("camera.detectors")}:</Typography>
                <SettingsButton
                    label={t("action.aim")}
                    icon={<CenterFocusStrong />}
                    disabled={!allowMove || !hasTargets}
                    onClick={onAimTarget}
                />
            </Stack>
        </Stack>
    )
}
