import { BatteryAlert, CloudOff, History, NotificationsActive, Sensors } from "@mui/icons-material"
import { Stack, Switch, Tooltip, Typography, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { NotificationIcon } from "./NotificationIcon"

export interface ZoneInfoProps {
    label: string
    tooltip: string
    disabled: boolean
    bypassed?: boolean
    alarm?: boolean
    alarmInMemory?: boolean
    violation?: boolean
    lowBattery?: boolean
    noConnection?: boolean
    flipBypass: (on: boolean) => void
}

export const ZoneInfo = (props: ZoneInfoProps) => {
    const {
        label,
        tooltip,
        disabled,
        bypassed,
        alarm,
        alarmInMemory,
        violation,
        lowBattery,
        noConnection,
        flipBypass,
    } = props
    const theme = useTheme()
    const { t } = useTranslation()

    return (
        <Stack
            direction="row"
            px={1}
            height="100%"
            sx={{ alignItems: "center", backgroundColor: theme.palette.background.paper }}
        >
            <Stack
                direction="row"
                sx={{
                    alignItems: "center",
                    flexGrow: 1,
                    overflow: "hidden",
                }}
            >
                {bypassed !== undefined && (
                    <Tooltip
                        title={bypassed ? t("action.unbypass_tooltip") : t("action.bypass_tooltip")}
                        disableInteractive
                    >
                        <span>
                            <Switch checked={bypassed} disabled={disabled} onClick={() => flipBypass(bypassed)} />
                        </span>
                    </Tooltip>
                )}
                <Tooltip title={tooltip} disableInteractive>
                    <Typography
                        sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                    >
                        {label}
                    </Typography>
                </Tooltip>
            </Stack>

            {alarm !== undefined && (
                <NotificationIcon
                    onTooltip={t("alarm.alarm_active")}
                    offTooltip={t("alarm.alarm_inactive")}
                    condition={alarm}
                    iconCtor={(p) => <NotificationsActive sx={p} />}
                />
            )}
            {alarmInMemory !== undefined && (
                <NotificationIcon
                    onTooltip={t("alarm.in_memory_active")}
                    offTooltip={t("alarm.in_memory_inactive")}
                    condition={alarmInMemory}
                    iconCtor={(p) => <History sx={p} />}
                />
            )}
            {violation !== undefined && (
                <NotificationIcon
                    onTooltip={t("alarm.violation_active")}
                    offTooltip={t("alarm.violation_inactive")}
                    condition={violation}
                    iconCtor={(p) => <Sensors sx={p} />}
                />
            )}
            {lowBattery !== undefined && (
                <NotificationIcon
                    onTooltip={t("alarm.low_battery_active")}
                    offTooltip={t("alarm.low_battery_inactive")}
                    condition={lowBattery}
                    iconCtor={(p) => <BatteryAlert sx={p} />}
                />
            )}
            {noConnection !== undefined && (
                <NotificationIcon
                    onTooltip={t("alarm.disconnect_active")}
                    offTooltip={t("alarm.disconnect_inactive")}
                    condition={noConnection}
                    iconCtor={(p) => <CloudOff sx={p} />}
                />
            )}
        </Stack>
    )
}
